export const PAY_NO = 0
export const PAY_DEPOSIT = 1
export const PAY_WAITING = 2
export const PAY_COMPLETED = 3

export const PAY_STATE_MAP = {
  [PAY_NO]: {
    state: "red",
    text: "未支付"
  },
  [PAY_DEPOSIT]: {
    state: "blue",
    text: "已付定金"
  },
  [PAY_WAITING]: {
    state: "orange",
    text: "待支付"
  },
  [PAY_COMPLETED]: {
    state: "green",
    text: "已支付"
  }
}
