<template>
  <a-card :title="title" :bordered="false" hoverable>
    <a slot="extra" @click="$router.go(-1)">返回</a>
    <a-table
      rowKey="id"
      :loading="spinning"
      :columns="columns"
      :data-source="data"
      :pagination="false"
    >
      <a-tooltip slot="id" slot-scope="scope, record">
        <template slot="title">
          查看订单详情
        </template>
        <router-link :to="{ path: '/order/info', query: { id: record.id } }">{{
          scope
        }}</router-link>
      </a-tooltip>
      <span slot="product" slot-scope="scope, record">
        {{ record.product_name }}
        <a-tag v-if="record.product_set_out === 1" color="green"
          >长岛出发</a-tag
        >
        <a-tag v-if="record.product_set_out === 2" color="pink">蓬莱出发</a-tag>
        <a-tag v-if="record.product_set_out === 3" color="red">烟台出发</a-tag>
        <a-tag v-if="record.product_holiday === 1" color="red"
          >区分节假日</a-tag
        >
        <a-tag v-if="record.product_days === 0" color="red">一日游</a-tag>
      </span>
      <span slot="people_number" slot-scope="scope, record">
        {{ record.people_number }}人
        <span v-if="record.child_number > 0">
          +{{ record.child_number }}幼儿
        </span>
      </span>
      <span slot="pay_state" slot-scope="scope">
        <a-badge :color="scope | payStateCode" :text="scope | payStateText" />
      </span>
      <span slot="total_price" slot-scope="scope" class="red"
        >¥{{ scope }}</span
      >
      <span slot="action" slot-scope="scope">
        <router-link :to="{ path: '/order/info', query: { id: scope.id } }"
          >订单详情</router-link
        >
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { PAY_STATE_MAP } from "@/utils/pay-state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        scopedSlots: { customRender: "id" }
      },
      {
        title: "时间",
        dataIndex: "tour_date"
      },
      {
        title: "产品",
        dataIndex: "product",
        scopedSlots: { customRender: "product" }
      },
      {
        title: "联系人",
        dataIndex: "contacts"
      },
      {
        title: "人数",
        dataIndex: "people_number",
        scopedSlots: { customRender: "people_number" }
      },
      {
        title: "支付",
        dataIndex: "pay_state",
        scopedSlots: { customRender: "pay_state" }
      },
      {
        title: "总价",
        dataIndex: "total_price",
        scopedSlots: { customRender: "total_price" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]

    return {
      spinning: true,
      columns: columns,
      data: []
    }
  },
  filters: {
    payStateCode(state) {
      return PAY_STATE_MAP[state].state
    },
    payStateText(state) {
      return PAY_STATE_MAP[state].text
    }
  },
  computed: {
    title() {
      return this.$route.meta.title + " 账单ID:" + this.$route.query.id
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.$request({
        url: "/getBillOrder",
        method: "get",
        params: {
          id: this.$route.query.id
        }
      }).then(res => {
        this.data = res.data
        this.spinning = false
      })
    } else {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-content {
  padding: 20px 20px;
}

.red {
  color: red;
}
</style>
