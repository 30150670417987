<template>
  <div>
    <a-row :gutter="10" class="page-head">
      <a-col :span="6"
        ><a-card
          class="page-card"
          :loading="loading"
          :bordered="false"
          hoverable
          @click="goPlanning"
          ><span class="page-card-title">待分配订单</span
          ><span class="page-card-text">{{ data.planning_count }}</span></a-card
        > </a-col
      ><a-col :span="6"
        ><a-card
          class="page-card"
          :loading="loading"
          :bordered="false"
          hoverable
          @click="goReady"
          ><span class="page-card-title">待服务订单</span
          ><span class="page-card-text">{{ data.ready_count }}</span></a-card
        > </a-col
      ><a-col :span="6"
        ><a-card
          class="page-card"
          :loading="loading"
          :bordered="false"
          hoverable
          @click="goComplete"
          ><span class="page-card-title" v-if="isStaff">待结算订单</span
          ><span class="page-card-title" v-else>待支付账单</span
          ><span class="page-card-text">{{ data.complete_count }}</span></a-card
        ></a-col
      ><a-col :span="6"
        ><a-card
          class="page-card"
          :loading="loading"
          :bordered="false"
          hoverable
          ><span class="page-card-title" v-if="isStaff">累计接待人数</span
          ><span class="page-card-title" v-else>累计人数</span
          ><span class="page-card-text">{{ data.people_count }}</span></a-card
        >
      </a-col></a-row
    >
    <a-card title="进行中的订单" :bordered="false" hoverable>
      <a-table
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data-source="data.data"
        :pagination="false"
      >
        <a-tooltip slot="id" slot-scope="scope, record">
          <template slot="title">
            查看订单详情
          </template>
          <router-link
            :to="{ path: '/order/info', query: { id: record.id } }"
            >{{ scope }}</router-link
          >
        </a-tooltip>
        <span slot="tour_date" slot-scope="scope">
          <span class="page-table-date">{{ scope | getDate }}</span>
        </span>
        <span slot="state" slot-scope="scope, record">
          <a-badge
            v-if="scope != 3"
            :color="scope | orderStateCode"
            :text="scope | orderStateText"
          />
          <a-badge
            v-else
            :color="inservice(record.tour_date).color"
            :text="inservice(record.tour_date).text"
          />
        </span>
        <span slot="product" slot-scope="scope, record">
          <a-tag v-if="record.type === 1" color="green">自营</a-tag>
          <a-tag v-if="record.type === 2" color="blue">OTA</a-tag>
          <a-tag v-if="record.type === 3" color="orange">分销</a-tag>
          {{ record.product_name }}
          <a-tag v-if="record.product_set_out === 1" color="green"
            >长岛出发</a-tag
          >
          <a-tag v-if="record.product_set_out === 2" color="pink"
            >蓬莱出发</a-tag
          >
          <a-tag v-if="record.product_set_out === 3" color="red"
            >烟台出发</a-tag
          >
          <a-tag v-if="record.product_holiday === 1" color="red"
            >区分节假日</a-tag
          >
          <a-tag v-if="record.product_days === 0" color="red">一日游</a-tag>
        </span>
        <a-tooltip slot="contacts" slot-scope="scope, record">
          <template slot="title"> TEL: {{ record.mobile }} </template>
          {{ scope }}
          <a-tag color="red">{{
            record.people_number + record.child_number
          }}</a-tag>
        </a-tooltip>
        <span slot="action" slot-scope="scope">
          <template v-if="$store.getters.getState.permission === 1">
            <!--已分配-->
            <router-link
              :to="{ path: '/order/complete', query: { id: scope.id } }"
              :disabled="!isRuning(scope.tour_date)"
              >完成</router-link
            >
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <router-link
                    :to="{ path: '/order/arrange', query: { id: scope.id } }"
                    >分配订单</router-link
                  >
                </a-menu-item>
                <a-menu-item>
                  <router-link
                    :to="{ path: '/order/edit', query: { id: scope.id } }"
                  >
                    编辑订单
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link
                    :to="{ path: '/order/return', query: { id: scope.id } }"
                  >
                    订单退改
                  </router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
          <template v-else>
            <router-link :to="{ path: '/order/info', query: { id: scope.id } }"
              >订单详情</router-link
            >
          </template>
        </span>
        <div slot="expandedRowRender" slot-scope="record">
          <span
            >{{ record.people_number }}人
            <span v-if="record.child_number > 0">
              +{{ record.child_number }}幼儿
            </span>
            <a-divider type="vertical"
          /></span>
          <span v-if="record.travel_agency_name"
            >{{ record.travel_agency_name }}<a-divider type="vertical"
          /></span>
          <span v-if="record.source"
            >{{ record.source | orderSourceText }}<a-divider type="vertical"
          /></span>
          <span v-if="record.create_time"
            >创建时间: {{ record.create_time }}<a-divider type="vertical"
          /></span>
          <span
            >订单总价: ¥{{ record.total_price }}<a-divider type="vertical"
          /></span>
          <span v-if="record.pay_state === 1"
            >定金: ¥{{ record.deposit }}<a-divider type="vertical"
          /></span>
          <span>
            <a-badge
              :color="record.pay_state | payStateCode"
              :text="record.pay_state | payStateText"
            />
          </span>
        </div>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import * as config from "@/config"
import { ORDER_STATE_MAP } from "@/utils/order-state-utils"
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"
import { PAY_STATE_MAP } from "@/utils/pay-state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px",
        scopedSlots: { customRender: "id" },
        sorter: (a, b) => b.id - a.id
      },
      {
        title: "时间",
        dataIndex: "tour_date",
        scopedSlots: { customRender: "tour_date" },
        sorter: (a, b) => {
          return (
            this.$moment(b.tour_date).unix() - this.$moment(a.tour_date).unix()
          )
        }
      },
      {
        title: "状态",
        dataIndex: "state",
        scopedSlots: { customRender: "state" }
      },
      {
        title: "产品",
        dataIndex: "product",
        scopedSlots: { customRender: "product" }
      },
      {
        title: "联系人",
        dataIndex: "contacts",
        scopedSlots: { customRender: "contacts" }
      },
      {
        title: "管家",
        dataIndex: "butler_name",
        scopedSlots: { customRender: "butler_name" }
      },
      {
        title: "酒店",
        dataIndex: "hotel_name",
        scopedSlots: { customRender: "hotel_name" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      columns: columns,
      loading: false,
      data: []
    }
  },
  filters: {
    orderStateCode(state) {
      return ORDER_STATE_MAP[state].state
    },
    orderStateText(state) {
      return ORDER_STATE_MAP[state].text
    },
    payStateCode(state) {
      return PAY_STATE_MAP[state].state
    },
    payStateText(state) {
      return PAY_STATE_MAP[state].text
    },
    orderSourceText(code) {
      return ORDER_SOURCE_MAP[code].text
    },
    getDate(date) {
      let arr = date.split("-")
      return arr[1] + "-" + arr[2]
    }
  },
  computed: {
    isStaff() {
      return !(
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    goPlanning() {
      this.$router.replace("/order/list?state=2")
    },
    goReady() {
      this.$router.replace("/order/list?state=3")
    },
    goComplete() {
      if (this.$store.getters.getState.permission === config.PERMISSION_ADMIN) {
        this.$router.replace("/settlement/index")
      }
      if (
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      ) {
        this.$router.replace("/travel-agency/bill-list")
      }
    },
    inservice(date) {
      let now = this.$moment().unix()
      let tour_date = this.$moment(date).unix()
      if (now > tour_date) {
        return { text: "进行中", color: "geekblue" }
      } else {
        return { text: "待服务", color: ORDER_STATE_MAP[3].state }
      }
    },
    isRuning(date) {
      let now = this.$moment().unix()
      let tour_date = this.$moment(date).unix()
      return now > tour_date
    },
    getData() {
      this.loading = true
      this.$request({
        url: "/dashboard",
        method: "get"
      }).then(res => {
        this.data = res.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.page-head {
  margin-bottom: 10px;
}

.page-card {
  padding: 20px;
}

.page-card-title {
  font-size: 13px;
  color: #4b4b4b;
}

.red {
  color: red;
}

.page-card-text {
  float: right;
  font-size: 14px;
  font-weight: bold;
  color: #f84020;
}

.page-table-date {
  color: rgba(255, 30, 0, 0.945);
}
</style>
