<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-form-model
      class="page-content"
      ref="iform"
      :model="formData"
      :rules="rules"
      @submit="handleSubmit"
      @submit.native.prevent
    >
      <a-form-model-item label="原密码" prop="old_password">
        <a-input-password
          v-model="formData.old_password"
          type="password"
          placeholder="请输入您的原密码"
          size="large"
        >
          <a-icon type="lock" slot="prefix" />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="password">
        <a-input-password
          v-model="formData.password"
          type="password"
          placeholder="请输入您的新密码"
          size="large"
        >
          <a-icon type="lock" slot="prefix" />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item label="确认新密码" prop="again_password">
        <a-input-password
          v-model="formData.again_password"
          type="password"
          placeholder="请确认您的新密码"
          size="large"
        >
          <a-icon type="lock" slot="prefix" />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          size="large"
          html-type="submit"
          :disabled="!isEnable"
          :loading="!isEnable"
        >
          修改密码
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入您的新密码"))
      } else if (value.length < 6) {
        callback(new Error("密码最少6位"))
      } else if (value !== this.formData.password) {
        callback(new Error("您两次输入的新密码不一致"))
      } else {
        callback()
      }
    }
    return {
      isEnable: true,
      formData: {
        old_password: undefined,
        password: undefined,
        again_password: undefined
      },
      rules: {
        old_password: [
          {
            required: true,
            message: "请输入您的原密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "密码最少6位",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入你的新密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "密码最少6位",
            trigger: "blur"
          }
        ],
        again_password: [
          { required: true, validator: validatePass, trigger: "change" }
        ]
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.isEnable = false
          const old_password = this.$md5(this.formData.old_password)
          const password = this.$md5(this.formData.password)
          this.$request({
            url: "/modifyPassword",
            method: "post",
            data: {
              old_password: old_password,
              password: password
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.replace("/")
              }, 2000)
            })
            .catch(() => {
              this.formData.old_password = ""
              this.formData.password = ""
              this.formData.again_password = ""
              this.isEnable = true
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-content {
  min-width: 260px;
  width: 350px;
  margin: 30px auto 0;
}

.ant-form-item button {
  width: 100%;
  margin-top: 30px;
}
</style>
