export const DELETE = 0
export const NORMAL = 1
export const PAUSE = 2
export const PEDDING = 3

export const STATE_MAP = {
  [DELETE]: {
    state: "red",
    text: "删除"
  },
  [NORMAL]: {
    state: "green",
    text: "正常"
  },
  [PAUSE]: {
    state: "orange",
    text: "暂停"
  },
  [PEDDING]: {
    state: "blue",
    text: "处理中"
  }
}
