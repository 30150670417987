<template>
  <a-spin :spinning="loading">
    <a-form-model
      ref="iform"
      class="page-form"
      :model="formData"
      :rules="rules"
      @submit="handleSubmit"
      @submit.native.prevent
    >
      <a-form-model-item
        label="周末为假期"
        labelAlign="left"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }"
      >
        <a-switch v-model="weekend" @change="onChange" />
      </a-form-model-item>
      <a-form-model-item label="假期名称" prop="name">
        <a-input
          v-model="formData.name"
          type="text"
          placeholder="请输入假期名称"
        >
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="假期时间" prop="date">
        <a-range-picker
          v-model="formData.date"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
        />
      </a-form-model-item>
      <a-form-model-item class="page-submit">
        <a-button
          type="primary"
          size="large"
          html-type="submit"
          :disabled="!isEnable"
          :loading="!isEnable"
        >
          新增假期
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-table
      class="page-table"
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :pagination="false"
    >
      <span slot="action" slot-scope="scope">
        <a-popconfirm
          placement="bottomRight"
          :title="getDeleteTitle(scope.name)"
          @confirm="deleteHoliday(scope.id)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </a-spin>
</template>

<script>
export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id"
      },
      {
        title: "名称",
        dataIndex: "name"
      },
      {
        title: "开始日期",
        dataIndex: "start_date"
      },
      {
        title: "结束日期",
        dataIndex: "end_date"
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      loading: false,
      isEnable: true,
      weekend: false,
      columns: columns,
      data: [],
      formData: {
        name: undefined,
        date: []
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入假期名称",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请输入有效的假期名称",
            trigger: "blur"
          },
          {
            max: 30,
            message: "请输入有效的假期名称",
            trigger: "blur"
          }
        ],
        date: [
          {
            required: true,
            message: "请设置假期时间",
            trigger: "change"
          }
        ]
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    onChange() {
      this.loading = true
      this.$request({
        url: "/holiday",
        method: "put",
        data: {
          weekend: this.weekend
        }
      })
        .then(res => {
          this.loading = false
          this.$message.success(res.message)
        })
        .catch(() => {
          this.loading = false
        })
    },
    getDeleteTitle(name) {
      return "您确定要删除" + name + "吗？"
    },
    deleteHoliday(id) {
      this.$request({
        url: "/holiday",
        method: "delete",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.findIndex(item => id === item.id)
        this.data.splice(index, 1)
        this.$message.success(res.message)
      })
    },
    getData() {
      this.loading = true
      this.$request({
        url: "/holiday",
        method: "get"
      }).then(res => {
        this.data = res.data.data
        this.weekend = res.data.weekend
        this.loading = false
      })
    },
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.isEnable = false
          this.$request({
            url: "/holiday",
            method: "post",
            data: {
              name: this.formData.name,
              start_date: this.formData.date[0],
              end_date: this.formData.date[1]
            }
          })
            .then(res => {
              this.isEnable = true
              this.formData.name = ""
              this.formData.date = []
              this.getData()
              this.$message.success(res.message)
            })
            .catch(() => {
              this.isEnable = true
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 6px;
}

.page-form {
  min-width: 300px;
  width: 400px;
}

.page-table {
  margin-top: 15px;
  width: 90%;
}

.page-submit {
  margin-top: 15px;
}
</style>
