<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <router-link slot="extra" :to="{ path: 'list' }"
      >新增旅行社账号</router-link
    >
    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <span slot="state" slot-scope="scope">
        <a-badge :color="scope | stateCode" :text="scope | stateText" />
      </span>
      <span slot="open_id" slot-scope="scope">
        <a-tag v-if="scope" color="green">已绑定</a-tag>
        <a-tag v-else color="orange">未绑定</a-tag>
      </span>
      <span slot="travel_agency_name" slot-scope="scope">
        <a-tag color="red">{{ scope }}</a-tag>
      </span>
      <span slot="last_login_time" slot-scope="scope">
        {{ scope | dateFormat }}
      </span>
      <span slot="action" slot-scope="scope">
        <router-link :to="{ path: 'account', query: { id: scope.id } }"
          >编辑</router-link
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="bottomRight"
          :title="getStateTitle(scope.username, scope.state)"
          @confirm="changeState(scope.id, scope.state)"
        >
          <a v-if="scope.state === NORMAL">暂停</a>
          <a v-else-if="scope.state === PAUSE">启用</a>
          <a v-else disabled="true">暂停</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="bottomRight"
          :title="getDeleteTitle(scope.username)"
          @confirm="remove(scope.id)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import moment from "moment"
import { PAGE_SIZE } from "@/config"
import { STATE_MAP, NORMAL, PAUSE } from "@/utils/state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px"
      },
      {
        title: "状态",
        dataIndex: "state",
        scopedSlots: { customRender: "state" },
        width: "120px"
      },
      {
        title: "微信",
        dataIndex: "open_id",
        scopedSlots: { customRender: "open_id" },
        width: "120px"
      },
      {
        title: "旅行社",
        dataIndex: "travel_agency_name",
        scopedSlots: { customRender: "travel_agency_name" }
      },
      {
        title: "用户名",
        dataIndex: "username"
      },
      {
        title: "手机号码",
        dataIndex: "mobile"
      },
      {
        title: "IP",
        dataIndex: "ip"
      },
      {
        title: "最后登录时间",
        dataIndex: "last_login_time",
        scopedSlots: { customRender: "last_login_time" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      NORMAL: NORMAL,
      PAUSE: PAUSE,
      columns: columns,
      loading: false,
      data: [],
      pagination: {}
    }
  },
  activated() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  filters: {
    stateCode(code) {
      return STATE_MAP[code].state
    },
    stateText(code) {
      return STATE_MAP[code].text
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return moment(timestamp * 1000).format("YYYY-MM-DD H:mm:ss")
      } else {
        return ""
      }
    }
  },
  methods: {
    getDeleteTitle(name) {
      return "您确定要删除" + name + "吗？"
    },
    remove(id) {
      this.$request({
        url: "/travelAgencyAccount",
        method: "delete",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data.splice(index, 1)
        this.$message.success(res.message)
      })
    },
    getStateTitle(name, state) {
      if (state == NORMAL) {
        return "您确定要暂停" + name + "吗？"
      } else {
        return "您确定要启用" + name + "吗？"
      }
    },
    changeState(id, state) {
      let newState
      if (state == NORMAL) {
        newState = PAUSE
      } else {
        newState = NORMAL
      }
      this.$request({
        url: "/travelAgencyAccount",
        method: "put",
        data: {
          id: id,
          state: newState
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data[index].state = newState
        this.$message.success(res.message)
      })
    },
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getTravelAgencyAccountList",
        method: "get",
        params: {
          page: page,
          size: size
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.ant-card {
  min-height: 100%;
}
</style>
