<template>
  <div>
    <a-descriptions size="middle" bordered>
      <a-descriptions-item label="线路产品" :span="3">
        <a-tag v-if="productInfo.type === 1" color="green">自营</a-tag>
        <a-tag v-if="productInfo.type === 2" color="blue">OTA</a-tag>
        <a-tag v-if="productInfo.type === 3" color="orange">分销</a-tag
        >{{ productInfo.name }}
        <a-tag v-if="productInfo.set_out === 1" color="green">仅长岛出发</a-tag>
        <a-tag v-if="productInfo.set_out === 2" color="pink"
          >支持岛外出发</a-tag
        >
        <a-tag v-if="productInfo.holiday === 1" color="red">区分节假日</a-tag>
        <a-tag v-if="productInfo.days === 0" color="red">一日游</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="产品详情" :span="3">
        <span>{{ productInfo.content }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="产品备注" :span="3" v-if="productInfo.remark">
        <span class="warning">{{ productInfo.remark }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="订单状态 ">
        <template v-if="orderInfo.state != undefined">
          <a-badge
            v-if="orderInfo.state != 3"
            :color="orderInfo.state | orderStateCode"
            :text="orderInfo.state | orderStateText"
          />
          <a-badge
            v-else
            :color="inservice(orderInfo.tour_date).color"
            :text="inservice(orderInfo.tour_date).text"
          />
        </template>
      </a-descriptions-item>
      <a-descriptions-item label="支付状态">
        <a-badge
          v-if="orderInfo.pay_state != undefined"
          :color="orderInfo.pay_state | payStateCode"
          :text="orderInfo.pay_state | payStateText"
        />
        <span
          v-if="orderInfo.pay_state != undefined && orderInfo.pay_state == 1"
          class="price"
        >
          {{ orderInfo.deposit }}元
        </span>
      </a-descriptions-item>
      <a-descriptions-item label="订单金额">
        <span class="price">¥ {{ orderInfo.total_price }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="联系人">
        {{ orderInfo.contacts }} {{ orderInfo.mobile }}
      </a-descriptions-item>
      <a-descriptions-item label="游玩日期">
        <span class="warning"
          >{{ orderInfo.tour_date }}
          <a-tag
            v-if="orderInfo.holiday_name && productInfo.holiday === 1"
            color="red"
          >
            {{ orderInfo.holiday_name }}</a-tag
          ></span
        >
      </a-descriptions-item>
      <a-descriptions-item label="订单人数">
        <span class="warning">
          {{ orderInfo.people_number }} 人
          <template v-if="orderInfo.child_number > 0">
            + {{ orderInfo.child_number }} 幼儿
          </template>
        </span>
      </a-descriptions-item>
      <a-descriptions-item label="其他信息" :span="3">
        <a-tag v-if="orderInfo.set_out === 1" color="green">长岛出发</a-tag>
        <a-tag v-if="orderInfo.set_out === 2" color="pink">蓬莱出发</a-tag>
        <a-tag v-if="orderInfo.set_out === 3" color="red">烟台出发</a-tag>
        <a-divider type="vertical" />
        <span v-if="orderInfo.travel_agency_name"
          >旅行社:{{ orderInfo.travel_agency_name }}<a-divider type="vertical"
        /></span>
        <span v-if="orderInfo.username"
          >用户:{{ orderInfo.username }}<a-divider type="vertical"
        /></span>
        <span v-if="orderInfo.source"
          >平台:{{ orderInfo.source | orderSourceText
          }}<a-divider type="vertical"
        /></span>
        <span v-if="orderInfo.agent_name"
          >用户:{{ orderInfo.agent_name }}<a-divider type="vertical"
        /></span>
        <span v-if="orderInfo.mobile_spare"
          >备用号码:{{ orderInfo.mobile_spare }}<a-divider type="vertical"
        /></span>
        <span v-if="orderInfo.mobile_docking"
          >对接司机号码:{{ orderInfo.mobile_docking }}<a-divider type="vertical"
        /></span>
        <span v-if="orderInfo.butler_name"
          >管家:{{ orderInfo.butler_name }}<a-divider type="vertical"
        /></span>
        <span v-if="orderInfo.hotel_name"
          >酒店:{{ orderInfo.hotel_name }}<a-divider type="vertical"
        /></span>
        <span class="warning" v-if="productInfo.change_time !== undefined">
          退改政策:<span v-if="productInfo.change_time > 0">
            提前{{ productInfo.change_time }}天退改</span
          >
          <span v-else>随时退改</span>
        </span>
      </a-descriptions-item>
      <a-descriptions-item v-if="orderInfo.remark" label="订单备注" :span="3">
        <span>{{ orderInfo.remark }}</span>
      </a-descriptions-item>
      <a-descriptions-item
        v-if="orderInfo.butler_remark && isStaff"
        label="管家备注"
        :span="3"
      >
        <span>{{ orderInfo.butler_remark }}</span>
      </a-descriptions-item>
      <a-descriptions-item
        v-if="orderInfo.hotel_remark && isStaff"
        label="酒店备注"
        :span="3"
      >
        <span>{{ orderInfo.hotel_remark }}</span>
      </a-descriptions-item>
      <a-descriptions-item
        v-if="tags.length > 0 && isStaff"
        label="订单标签"
        :span="3"
      >
        <a-tag v-for="tag in tags" :key="tag" color="red">{{ tag }}</a-tag>
      </a-descriptions-item>
    </a-descriptions>
    <!--table-->
    <a-table
      rowKey="id"
      class="table"
      :columns="columns"
      :dataSource="visitorList"
      :pagination="false"
      bordered
    >
      <template slot="price" slot-scope="scope">
        <span class="price">¥ {{ scope }}</span>
      </template>
      <template slot="sex" slot-scope="scope">
        <a-tag v-if="scope === 0" color="red">女</a-tag>
        <a-tag v-if="scope === 1" color="blue">男</a-tag>
      </template>
      <template slot="age" slot-scope="scope, record">
        <a-tag
          v-if="idToAge(record.id_card, orderInfo.tour_date) < 18"
          color="red"
          >{{ idToAge(record.id_card, orderInfo.tour_date) }}</a-tag
        >
        <a-tag
          v-else-if="idToAge(record.id_card, orderInfo.tour_date) >= 60"
          color="red"
          >{{ idToAge(record.id_card, orderInfo.tour_date) }}</a-tag
        >
        <a-tag v-else color="green">{{
          idToAge(record.id_card, orderInfo.tour_date)
        }}</a-tag>
      </template>
    </a-table>
  </div>
</template>

<script>
import * as config from "@/config"
import { idToAge } from "@/utils/utils"
import { ORDER_STATE_MAP } from "@/utils/order-state-utils"
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"
import { PAY_STATE_MAP } from "@/utils/pay-state-utils"

export default {
  name: "OrderInfoView",
  props: {
    orderData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    orderInfo() {
      if (this.orderData.order_info) {
        return this.orderData.order_info
      } else {
        return {}
      }
    },
    productInfo() {
      if (this.orderData.product_info) {
        return this.orderData.product_info
      } else {
        return {}
      }
    },
    visitorList() {
      if (this.orderData.visitor_list) {
        return this.orderData.visitor_list
      } else {
        return []
      }
    },
    tags() {
      if (this.orderData.order_info && this.orderData.order_info.tags) {
        return this.orderData.order_info.tags.split(",")
      } else {
        return []
      }
    },
    isStaff() {
      return !(
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    }
  },
  data() {
    return {
      columns: [
        {
          title: "类型",
          dataIndex: "price_name"
        },
        {
          title: "价格",
          dataIndex: "price",
          scopedSlots: { customRender: "price" }
        },
        {
          title: "年龄",
          dataIndex: "age",
          scopedSlots: { customRender: "age" }
        },
        {
          title: "性别",
          dataIndex: "sex",
          scopedSlots: { customRender: "sex" }
        },
        {
          title: "姓名",
          dataIndex: "name"
        },
        {
          title: "身份证号码",
          dataIndex: "id_card"
        }
      ]
    }
  },
  filters: {
    orderStateCode(state) {
      return ORDER_STATE_MAP[state].state
    },
    orderStateText(state) {
      return ORDER_STATE_MAP[state].text
    },
    payStateCode(state) {
      return PAY_STATE_MAP[state].state
    },
    payStateText(state) {
      return PAY_STATE_MAP[state].text
    },
    orderSourceText(code) {
      return ORDER_SOURCE_MAP[code].text
    }
  },
  methods: {
    idToAge,
    inservice(date) {
      let now = this.$moment().unix()
      let tour_date = this.$moment(date).unix()
      if (now > tour_date) {
        return { text: "进行中", color: "geekblue" }
      } else {
        return { text: "待服务", color: ORDER_STATE_MAP[3].state }
      }
    }
  }
}
</script>

<style scoped>
.table {
  margin: 20px 0;
}

.price {
  font-weight: bold;
  color: red;
}

.warning {
  color: rgb(245, 78, 0);
}
</style>
