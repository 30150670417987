<template>
  <a-result status="success" title="订单录入完成" :sub-title="title">
    <template #extra>
      <a-button @click="again">
        继续录入订单
      </a-button>
      <a-button @click="goList" type="primary">
        查询订单信息
      </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  props: {
    order_id: {
      type: String,
      default: undefined
    }
  },
  computed: {
    title() {
      return "订单编号:" + this.order_id + " 等待分配中..."
    }
  },
  methods: {
    again() {
      if (this.$route.path === "/order/edit") {
        this.$router.replace("/order/create")
      } else {
        this.$router.go(0)
      }
    },
    goList() {
      this.$router.replace("/order/list")
    }
  }
}
</script>

<style></style>
