<template>
  <a-card :title="title" :bordered="false" hoverable>
    <a-spin :spinning="spinning" class="page">
      <a-form-model
        ref="iform"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
        :model="formData"
        :rules="rules"
        @submit="submit"
        @submit.native.prevent
      >
        <a-form-model-item label="游玩日期" prop="tour_date">
          <a-date-picker
            class="page-input-width"
            v-model="formData.tour_date"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            placeholder="请选择游玩日期"
          />
        </a-form-model-item>
        <a-form-model-item label="来源平台" prop="source">
          <a-select
            class="page-input-width"
            v-model="formData.source"
            placeholder="请选择来源平台"
          >
            <template v-for="key in 8">
              <a-select-option :key="key" :value="key">{{
                ORDER_SOURCE_MAP[key].text
              }}</a-select-option>
            </template>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="入住酒店" prop="hotel_id">
          <a-select
            class="page-input-width"
            v-model="formData.hotel_id"
            placeholder="请选择酒店"
          >
            <a-select-option
              v-for="hotel in hotels"
              :value="hotel.id"
              :key="hotel.id"
              >{{ hotel.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="入住天数" prop="days">
          <a-input-number
            class="page-input-width"
            v-model="formData.days"
            :min="1"
            placeholder="请输入入住天数"
          />
        </a-form-model-item>
        <a-form-model-item label="游玩人数" prop="people_number">
          <a-input-number
            class="page-input-width"
            v-model="formData.people_number"
            :min="1"
            placeholder="请输入游玩人数"
          />
        </a-form-model-item>
        <a-form-model-item label="联系人姓名" prop="contacts">
          <a-input
            class="page-input-width"
            v-model="formData.contacts"
            type="text"
            placeholder="请输入联系人姓名"
          >
            <a-icon type="idcard" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号码" prop="mobile">
          <a-input
            class="page-input-width"
            v-model="formData.mobile"
            type="number"
            placeholder="请输入联系人手机号码"
          >
            <a-icon type="mobile" slot="prefix" />
          </a-input>
        </a-form-model-item>

        <a-form-model-item label="订单备注" prop="remark">
          <a-textarea
            class="page-input-width"
            v-model="formData.remark"
            :auto-size="{ minRows: 3, maxRows: 6 }"
            placeholder="请输入订单备注"
          />
        </a-form-model-item>
        <a-row>
          <a-col :span="15" :offset="5">
            <a-button type="primary" html-type="submit" :loading="spinning">
              确认提交
            </a-button></a-col
          >
        </a-row>
      </a-form-model>
    </a-spin>
  </a-card>
</template>

<script>
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"

export default {
  data() {
    const rules = {
      tour_date: [
        {
          required: true,
          message: "请选择游玩日期",
          trigger: "change"
        }
      ],
      source: [
        {
          required: true,
          message: "请选择来源平台",
          trigger: "change"
        }
      ],
      hotel_id: [
        {
          required: true,
          message: "请选择入住酒店",
          trigger: "change"
        }
      ],
      days: [
        {
          required: true,
          message: "请输入入住天数",
          trigger: "blur"
        },
        {
          pattern: /^\d+$/,
          message: "请输入有效的天数",
          trigger: "blur"
        }
      ],
      people_number: [
        {
          required: true,
          message: "请输入游玩人数",
          trigger: "blur"
        },
        {
          pattern: /^\d+$/,
          message: "请输入有效的人数",
          trigger: "blur"
        }
      ],
      contacts: [
        {
          min: 2,
          message: "请输入有效的联系人姓名",
          trigger: "blur"
        },
        {
          max: 30,
          message: "请输入有效的联系人姓名",
          trigger: "blur"
        }
      ],
      mobile: [
        {
          pattern: /^1[23456789]\d{9}$/,
          message: "请输入有效的联系人手机号码",
          trigger: "blur"
        }
      ],
      remark: [
        {
          max: 300,
          message: "最多输入300字符",
          trigger: "change"
        }
      ]
    }
    return {
      ORDER_SOURCE_MAP: ORDER_SOURCE_MAP,
      rules: rules,
      spinning: true,
      hotels: [],
      formData: {
        id: undefined,
        tour_date: undefined,
        source: undefined,
        hotel_id: undefined,
        days: undefined,
        people_number: undefined,
        contacts: undefined,
        mobile: undefined,
        remark: undefined
      }
    }
  },
  computed: {
    title() {
      if (this.$route.query.id) {
        return "修改记录 ID:" + this.$route.query.id
      } else {
        return this.$route.meta.title
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      let request1 = this.$request({
        url: "/getButlerAndHotel",
        method: "get"
      })
      let request2 = this.$request({
        url: "/record",
        method: "get",
        params: {
          id: this.$route.query.id
        }
      })
      Promise.all([request1, request2]).then(res => {
        this.hotels = res[0].data.hotels
        this.formData = res[1].data
        this.spinning = false
      })
    } else {
      this.$request({
        url: "/getButlerAndHotel",
        method: "get"
      }).then(res => {
        this.hotels = res.data.hotels
        this.spinning = false
      })
    }
  },
  methods: {
    submit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.spinning = true
          let method = this.formData.id ? "put" : "post"
          this.$request({
            url: "/record",
            method: method,
            data: this.formData
          })
            .then(res => {
              this.spinning = false
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.replace("/order/record-list")
              }, 2000)
            })
            .catch(() => {
              this.spinning = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.page {
  padding-top: 40px;
}

.page-input-width {
  width: 50%;
  min-width: 350px;
}

.ant-btn {
  margin: 30px 0;
  width: 30%;
  min-width: 200px;
}
</style>
