<template>
  <a-row :gutter="10">
    <a-col :span="8"
      ><a-card title="酒店人数统计" :bordered="false" hoverable>
        <a-select
          slot="extra"
          v-model="year"
          placeholder="统计年份"
          @change="selectChange"
          class="page-select"
        >
          <a-select-option :value="2021">2021年</a-select-option>
          <a-select-option :value="2022">2022年</a-select-option>
          <a-select-option :value="2023">2023年</a-select-option>
          <a-select-option :value="2024">2024年</a-select-option>
          <a-select-option :value="2025">2025年</a-select-option>
        </a-select>

        <a-table
          rowKey="id"
          :loading="loading"
          :columns="columns"
          :data-source="data"
          :pagination="false"
        >
          <span slot="state" slot-scope="scope">
            <a-badge :color="scope | stateCode" :text="scope | stateText" />
          </span>
          <span slot="people_number" slot-scope="scope">
            <a-tag v-if="scope >= 100" color="red">{{ scope }}</a-tag>
            <a-tag v-else-if="scope && scope < 100" color="green">{{
              scope
            }}</a-tag>
            <a-tag v-else color="green">0</a-tag>
          </span>
        </a-table>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { STATE_MAP } from "@/utils/state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px"
      },
      {
        title: "酒店名称",
        dataIndex: "name"
      },
      {
        title: "人数统计",
        dataIndex: "people_number",
        scopedSlots: { customRender: "people_number" }
      }
    ]
    return {
      columns: columns,
      year: undefined,
      loading: false,
      data: []
    }
  },
  filters: {
    stateCode(code) {
      return STATE_MAP[code].state
    },
    stateText(code) {
      return STATE_MAP[code].text
    }
  },
  mounted() {
    this.year = Number(this.$moment().format("YYYY"))
    this.getData()
  },
  methods: {
    selectChange() {
      this.getData()
    },
    getData() {
      this.loading = true
      this.$request({
        url: "/getHotelRecord",
        method: "get",
        params: {
          year: this.year
        }
      }).then(res => {
        this.data = res.data
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.ant-card {
  min-height: 100%;
}

.red {
  color: red;
}

.page-select {
  width: 120px;
  margin-left: 30px;
}
</style>
