export const ACCOUNT_PURP0SE_MAP = {
  1: "其他用途",
  2: "景区门票",
  3: "网络推广",
  4: "办公器材",
  5: "餐饮消费",
  6: "公司分红"
}

export const ACCOUNT_SOURCE_MAP = {
  1: "其他收入",
  2: "线路收入",
  3: "广告推广",
  4: "视频拍摄",
  5: "景区门票",
  6: "技术开发"
}

export const ORDER_SOURCE_MAP = {
  1: {
    allshow: true,
    text: "其他/线下"
  },
  2: {
    allshow: false,
    text: "网站"
  },
  3: {
    allshow: false,
    text: "抖音"
  },
  4: {
    allshow: false,
    text: "微信"
  },
  5: {
    allshow: true,
    text: "携程"
  },
  6: {
    allshow: true,
    text: "美团"
  },
  7: {
    allshow: true,
    text: "飞猪"
  },
  8: {
    allshow: true,
    text: "马蜂窝"
  }
}
