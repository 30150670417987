<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-button
      slot="extra"
      type="primary"
      :disabled="!isEnable"
      :loading="!isEnable"
      @click="handleSubmit"
    >
      确认提交
    </a-button>
    <a-spin :spinning="spinning">
      <a-form-model
        class="page-content"
        ref="iform"
        :model="formData"
        :rules="rules"
      >
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="旅行社名称" prop="name">
              <a-input
                v-model="formData.name"
                type="text"
                placeholder="请输入旅行社名称"
              >
                <a-icon type="global" slot="prefix" />
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!--Table-->
        <a-form-model-item label="选择OTA产品">
          <a-table
            rowKey="id"
            :columns="columns"
            :data-source="productList"
            :pagination="false"
            :row-selection="{
              selectedRowKeys: formData.product,
              onChange: onSelectChange
            }"
          >
            <span slot="state" slot-scope="scope">
              <a-badge :color="scope | stateCode" :text="scope | stateText" />
            </span>
            <span slot="name" slot-scope="scope, record">
              <a-tag v-if="record.type === 1" color="green">自营</a-tag>
              <a-tag v-if="record.type === 2" color="blue">OTA</a-tag>
              <a-tag v-if="record.type === 3" color="orange">分销</a-tag>
              {{ record.name }}
              <a-tag v-if="record.set_out === 1" color="green">长岛出发</a-tag>
              <a-tag v-if="record.set_out === 2" color="pink">蓬莱出发</a-tag>
              <a-tag v-if="record.set_out === 3" color="red">烟台出发</a-tag>
              <a-tag v-if="record.holiday === 1" color="red">区分节假日</a-tag>
              <a-tag v-if="record.days === 0" color="red">一日游</a-tag>
            </span>
            <span slot="min_people" slot-scope="scope">
              <a-tag color="purple">{{ scope }} 人</a-tag>
            </span>
            <span slot="date" slot-scope="scope, record">
              <span
                :class="{ red: isExpire(record.start_date, record.end_date) }"
                >{{ record.start_date }} ～ {{ record.end_date }}</span
              >
            </span>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-card>
</template>

<script>
import { STATE_MAP } from "@/utils/state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px"
      },
      {
        title: "状态",
        dataIndex: "state",
        scopedSlots: { customRender: "state" }
      },
      {
        title: "产品名称",
        dataIndex: "name",
        scopedSlots: { customRender: "name" }
      },
      {
        title: "最低人数",
        dataIndex: "min_people",
        scopedSlots: { customRender: "min_people" }
      },
      {
        title: "有效时间",
        key: "date",
        scopedSlots: { customRender: "date" }
      }
    ]

    const rules = {
      name: [
        {
          required: true,
          message: "请输入旅行社名称",
          trigger: "blur"
        },
        {
          min: 2,
          message: "请输入有效的旅行社名称",
          trigger: "blur"
        },
        {
          max: 30,
          message: "请输入有效的旅行社名称",
          trigger: "blur"
        }
      ]
    }

    return {
      isEnable: true,
      spinning: true,
      columns: columns,
      rules: rules,
      productList: undefined,
      formData: {
        id: undefined,
        name: undefined,
        product: []
      }
    }
  },
  filters: {
    stateCode(code) {
      return STATE_MAP[code].state
    },
    stateText(code) {
      return STATE_MAP[code].text
    }
  },
  mounted() {
    this.$request({
      url: "/getProductInfo",
      method: "get",
      params: {
        type: 2
      }
    }).then(res => {
      this.productList = res.data
      if (this.$route.path === "/travel-agency/edit") {
        if (this.$route.query.id) {
          this.formData.id = this.$route.query.id
          this.$request({
            url: "/travelAgency",
            method: "get",
            params: {
              id: this.formData.id
            }
          }).then(res => {
            this.formData.name = res.data.name
            if (res.data.product === "") {
              this.formData.product = []
            } else {
              let product = res.data.product.split(",")
              for (let i = 0; i < product.length; i++) {
                let pid = parseInt(product[i])
                for (let j = 0; j < this.productList.length; j++) {
                  if (this.productList[j].id == pid) {
                    this.formData.product.push(pid)
                  }
                }
              }
            }
            this.spinning = false
          })
        } else {
          this.$router.replace("/travel-agency/list")
        }
      } else {
        this.spinning = false
      }
    })
  },
  methods: {
    isExpire(start_date, end_date) {
      let now = this.$moment(this.$moment().format("YYYY-MM-DD")).unix()
      let start = this.$moment(start_date).unix()
      let end = this.$moment(end_date).unix()
      return now < start || now > end
    },
    onSelectChange(selectedRowKeys) {
      this.formData.product = selectedRowKeys
    },
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.isEnable = false
          let method = this.formData.id ? "put" : "post"
          this.$request({
            url: "/travelAgency",
            method: method,
            data: {
              id: this.formData.id,
              name: this.formData.name,
              product: this.formData.product
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.replace("/travel-agency/list")
              }, 2000)
            })
            .catch(() => {
              this.isEnable = true
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-content {
  padding: 20px 20px;
}

.red {
  color: red;
}
</style>
