<template>
  <a-card
    :title="$route.meta.title + ' ID:' + $route.query.id"
    :bordered="false"
    hoverable
  >
    <a slot="extra" @click="$router.go(-1)">返回</a>
    <a-spin :spinning="spinning">
      <div class="page">
        <OrderInfoView :orderData="orderData" />
        <!--form-->
        <a-form-model
          ref="iform"
          :model="formData"
          :rules="rules"
          @submit="handleSubmit"
          @submit.native.prevent
        >
          <a-row :gutter="30">
            <a-col :span="8"
              ><a-form-model-item label="订单备注" prop="remark">
                <a-textarea
                  v-model="formData.remark"
                  :auto-size="{ minRows: 3, maxRows: 6 }"
                  placeholder="订单备注"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="管家备注" prop="butler_remark">
                <a-textarea
                  v-model="formData.butler_remark"
                  :auto-size="{ minRows: 3, maxRows: 6 }"
                  placeholder="管家备注(可选)"
                /> </a-form-model-item
            ></a-col>
            <a-col :span="8" v-if="productInfo.days != 0"
              ><a-form-model-item label="酒店备注" prop="hotel_remark">
                <a-textarea
                  v-model="formData.hotel_remark"
                  :auto-size="{ minRows: 3, maxRows: 6 }"
                  placeholder="酒店备注(可选)"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :span="8">
              <a-form-model-item label="订单标签">
                <div>
                  <template v-for="tag in formData.tags">
                    <a-tag
                      :key="tag"
                      closable
                      color="red"
                      @close="tagClose(tag)"
                    >
                      {{ tag }}
                    </a-tag>
                  </template>
                  <template v-if="formData.tags.length < 3">
                    <a-input
                      v-if="tagInputVisible"
                      ref="tagInput"
                      type="text"
                      class="page-tag-width"
                      :value="tagInputValue"
                      size="small"
                      @change="tagInputChange"
                      @blur="tagInputConfirm"
                      @keyup.enter="tagInputConfirm"
                    >
                      <a-icon slot="addonAfter" type="check" />
                    </a-input>
                    <a-tag v-else class="page-tag-add" @click="showTagInput">
                      <a-icon type="plus" /> 添加标签
                    </a-tag>
                  </template>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="选择管家" prop="butler_id">
                <a-select v-model="formData.butler_id" placeholder="请选择管家">
                  <a-select-option
                    v-for="butler in butlers"
                    :value="butler.id"
                    :key="butler.id"
                    >{{ butler.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="productInfo.days != 0">
              <a-form-model-item label="选择酒店" prop="hotel_id">
                <a-select v-model="formData.hotel_id" placeholder="请选择酒店">
                  <a-select-option
                    v-for="hotel in hotels"
                    :value="hotel.id"
                    :key="hotel.id"
                    >{{ hotel.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <template>
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              :loading="loading"
              class="page-button"
            >
              确认提交
            </a-button>
            <a-button
              v-if="orderData.order_info.state == 3"
              size="large"
              :loading="loading"
              class="page-button"
              @click="cancel"
            >
              撤销分配
            </a-button>
          </template>
        </a-form-model>
      </div>
    </a-spin>
  </a-card>
</template>

<script>
import OrderInfoView from "@/components/OrderInfoView.vue"

export default {
  components: { OrderInfoView },
  data() {
    const rules = {
      remark: [
        {
          max: 300,
          message: "最多输入300字符",
          trigger: "change"
        }
      ],
      butler_remark: [
        {
          max: 300,
          message: "最多输入300字符",
          trigger: "change"
        }
      ],
      hotel_remark: [
        {
          max: 300,
          message: "最多输入300字符",
          trigger: "change"
        }
      ]
    }

    return {
      rules: rules,
      spinning: true,
      loading: false,
      tagInputVisible: false,
      tagInputValue: undefined,
      orderData: {},
      productInfo: {},
      butlers: [],
      hotels: [],
      formData: {
        id: undefined,
        butler_id: undefined,
        hotel_id: undefined,
        remark: undefined,
        butler_remark: undefined,
        hotel_remark: undefined,
        tags: []
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.formData.id = this.$route.query.id
      let request1 = this.$request({
        url: "/getOrderInfo",
        method: "get",
        params: {
          id: this.formData.id
        }
      })
      let request2 = this.$request({
        url: "/getButlerAndHotel",
        method: "get"
      })
      Promise.all([request1, request2]).then(res => {
        this.orderData = res[0].data
        this.productInfo = this.orderData.product_info
        const orderInfo = this.orderData.order_info
        if (orderInfo.butler_id) {
          this.formData.butler_id = orderInfo.butler_id
        }
        if (orderInfo.hotel_id) {
          this.formData.hotel_id = orderInfo.hotel_id
        }
        this.formData.remark = orderInfo.remark
        this.formData.butler_remark = orderInfo.butler_remark
        this.formData.hotel_remark = orderInfo.hotel_remark
        if (orderInfo.tags) {
          this.formData.tags = orderInfo.tags.split(",")
        }
        this.butlers = res[1].data.butlers
        this.hotels = res[1].data.hotels
        this.spinning = false
      })
    } else {
      this.$router.go(-1)
    }
  },
  methods: {
    showTagInput() {
      this.tagInputVisible = true
      this.$nextTick(function() {
        this.$refs.tagInput.focus()
      })
    },
    tagClose(removedTag) {
      const tags = this.formData.tags.filter(tag => tag !== removedTag)
      this.formData.tags = tags
    },
    tagInputChange(e) {
      this.tagInputValue = e.target.value
    },
    tagInputConfirm() {
      let tagInputValue = this.tagInputValue
      if (tagInputValue) {
        if (tagInputValue.length > 8) {
          tagInputValue = tagInputValue.slice(0, 8)
        }
        if (tagInputValue && this.formData.tags.indexOf(tagInputValue) === -1) {
          this.formData.tags.push(tagInputValue)
        }
      }
      this.tagInputVisible = false
      this.tagInputValue = undefined
    },
    //提交数据
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.loading = true
          this.$request({
            url: "/arrange",
            method: "post",
            data: this.formData
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.go(-1)
              }, 2000)
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    cancel() {
      this.loading = true
      this.$request({
        url: "/cancelArrange",
        method: "post",
        data: {
          id: this.formData.id
        }
      })
        .then(res => {
          this.$message.success(res.message)
          setTimeout(() => {
            this.$router.go(-1)
          }, 2000)
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.page {
  padding: 20px;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-button {
  width: 20%;
  margin: 30px 0px 30px 30px;
  float: right;
}

.page-tag-add {
  background: #fff;
  border-style: dashed;
}

.page-tag-width {
  width: 170px;
}

.warning {
  color: rgb(245, 78, 0);
}
</style>
