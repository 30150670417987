<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <a-tooltip slot="order_id" slot-scope="scope">
        <template slot="title">
          查看订单详情
        </template>
        <router-link :to="{ path: '/order/info', query: { id: scope } }">{{
          scope
        }}</router-link>
      </a-tooltip>
      <span slot="type" slot-scope="scope">
        <a-tag v-if="scope === 1" color="red">酒店</a-tag>
        <a-tag v-else color="green">管家</a-tag>
      </span>
      <span slot="amount" slot-scope="scope" class="red">¥ {{ scope }}</span>
      <span
        slot="expandedRowRender"
        slot-scope="record"
        class="page-table-info"
      >
        结算备注: {{ record.desc }}
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { PAGE_SIZE } from "@/config"

export default {
  data() {
    const columns = [
      {
        title: "订单ID",
        dataIndex: "order_id",
        scopedSlots: { customRender: "order_id" }
      },
      {
        title: "类型",
        dataIndex: "type",
        scopedSlots: { customRender: "type" }
      },
      {
        title: "结算时间",
        dataIndex: "payment_time"
      },
      {
        title: "交易编号",
        dataIndex: "trade_no"
      },
      {
        title: "收款人",
        dataIndex: "name"
      },
      {
        title: "金额",
        dataIndex: "amount",
        scopedSlots: { customRender: "amount" }
      },
      {
        title: "OPEN ID",
        dataIndex: "open_id"
      }
    ]
    return {
      columns: columns,
      loading: false,
      data: [],
      pagination: {}
    }
  },
  activated() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  methods: {
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getSettlementRecord",
        method: "get",
        params: {
          page: page,
          size: size
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.ant-card {
  min-height: 100%;
}

.red {
  color: red;
}

.page-table-info {
  font-size: 12px;
}
</style>
