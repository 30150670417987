<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <div slot="extra">
      <a-date-picker
        class="page-select"
        v-model="tour_date"
        format="YYYY-MM-DD"
        valueFormat="YYYY-MM-DD"
        placeholder="筛选日期"
        @change="selectChange"
      />
      <a-select
        v-if="isStaff"
        v-model="type"
        placeholder="订单类型"
        @change="selectChange"
        class="page-select"
      >
        <a-select-option v-if="type != undefined" :value="undefined"
          >全部订单</a-select-option
        >
        <a-select-option :value="1">自营订单</a-select-option>
        <a-select-option :value="2">OTA订单</a-select-option>
        <a-select-option :value="3">分销订单</a-select-option>
      </a-select>
      <a-select
        v-model="state"
        placeholder="订单状态"
        @change="selectChange"
        class="page-select"
      >
        <a-select-option v-if="state != undefined" :value="undefined"
          >全部订单</a-select-option
        >
        <a-select-option :value="0">已取消订单</a-select-option>
        <a-select-option :value="2">待分配订单</a-select-option>
        <a-select-option :value="3">已分配订单</a-select-option>
        <a-select-option :value="4">已完成订单</a-select-option>
        <a-select-option :value="5">已结算订单</a-select-option>
      </a-select>
    </div>

    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <a-tooltip slot="id" slot-scope="scope, record">
        <template slot="title">
          查看订单详情
        </template>
        <router-link :to="{ path: 'info', query: { id: record.id } }">{{
          scope
        }}</router-link>
      </a-tooltip>
      <span slot="tour_date" slot-scope="scope">
        <span class="page-table-date">{{ scope | getDate }}</span>
      </span>
      <span slot="state" slot-scope="scope, record">
        <a-badge
          v-if="scope != 3"
          :color="scope | orderStateCode"
          :text="scope | orderStateText"
        />
        <a-badge
          v-else
          :color="inservice(record.tour_date).color"
          :text="inservice(record.tour_date).text"
        />
      </span>
      <span slot="set_out" slot-scope="scope, record">
        <a-tag v-if="record.set_out === 1" color="green">长岛</a-tag>
        <a-tag v-if="record.set_out === 2" color="pink">蓬莱</a-tag>
        <a-tag v-if="record.set_out === 3" color="red">烟台</a-tag>
      </span>
      <span slot="product" slot-scope="scope, record">
        <a-tag v-if="record.type === 1" color="green">自营</a-tag>
        <a-tag v-if="record.type === 2" color="blue">OTA</a-tag>
        <a-tag v-if="record.type === 3" color="orange">分销</a-tag>
        {{ record.product_name }}
        <a-tag v-if="record.product_holiday === 1" color="red"
          >区分节假日</a-tag
        >
        <a-tag v-if="record.product_days === 0" color="red">一日游</a-tag>
      </span>
      <a-tooltip slot="contacts" slot-scope="scope, record">
        <template slot="title"> TEL: {{ record.mobile }} </template>
        {{ scope }}
        <a-tag color="red">{{
          record.people_number + record.child_number
        }}</a-tag>
      </a-tooltip>
      <span slot="action" slot-scope="scope">
        <template v-if="$store.getters.getState.permission === 1">
          <!--待分配-->
          <template v-if="scope.state == 2">
            <router-link :to="{ path: 'arrange', query: { id: scope.id } }"
              >分配</router-link
            >
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <router-link :to="{ path: 'edit', query: { id: scope.id } }">
                    编辑订单
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <a @click="showCancelDialog(scope.id)"> 取消订单 </a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
          <!--已分配-->
          <template v-else-if="scope.state == 3">
            <router-link :to="{ path: 'complete', query: { id: scope.id } }"
              >完成</router-link
            >
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <router-link
                    :to="{ path: 'arrange', query: { id: scope.id } }"
                    >分配订单</router-link
                  >
                </a-menu-item>
                <a-menu-item>
                  <router-link :to="{ path: 'edit', query: { id: scope.id } }">
                    编辑订单
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link
                    :to="{ path: 'return', query: { id: scope.id } }"
                  >
                    订单退改
                  </router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
          <!--已完成-->
          <template v-else-if="scope.state == 4">
            <router-link :to="{ path: 'info', query: { id: scope.id } }"
              >详情</router-link
            >
            <a-divider type="vertical" />
            <router-link
              :to="{ path: 'complete', query: { id: scope.id } }"
              :disabled="scope.pay_state == 2"
              >撤回</router-link
            >
          </template>
          <!--其他-->
          <template v-else>
            <router-link :to="{ path: 'info', query: { id: scope.id } }"
              >订单详情</router-link
            >
          </template>
        </template>
        <template v-else>
          <template v-if="scope.state == 2">
            <router-link :to="{ path: 'info', query: { id: scope.id } }"
              >详情</router-link
            >
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <router-link :to="{ path: 'edit', query: { id: scope.id } }">
                    编辑订单
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    placement="bottomRight"
                    title="您确定要取消该订单吗?"
                    @confirm="cancel(scope.id)"
                  >
                    <a>取消订单</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
          <template v-else>
            <router-link :to="{ path: 'info', query: { id: scope.id } }"
              >订单详情</router-link
            >
          </template>
        </template>
      </span>
      <div slot="expandedRowRender" slot-scope="record" class="page-table-info">
        <span
          >{{ record.people_number }}人
          <span v-if="record.child_number > 0">
            +{{ record.child_number }}幼儿
          </span>
          <a-divider type="vertical"
        /></span>
        <span v-if="record.travel_agency_name"
          >{{ record.travel_agency_name }}<a-divider type="vertical"
        /></span>
        <span v-if="record.source"
          >{{ record.source | orderSourceText }}<a-divider type="vertical"
        /></span>
        <span v-if="record.create_time"
          >创建时间: {{ record.create_time }}<a-divider type="vertical"
        /></span>
        <span
          >订单总价: ¥{{ record.total_price }}<a-divider type="vertical"
        /></span>
        <span v-if="record.pay_state === 1"
          >定金: ¥{{ record.deposit }}<a-divider type="vertical"
        /></span>
        <span>
          <a-badge
            class="page-table-badge"
            :color="record.pay_state | payStateCode"
            :text="record.pay_state | payStateText"
          />
        </span>
      </div>
    </a-table>
  </a-card>
</template>

<script>
import * as config from "@/config"
import { ORDER_STATE_MAP } from "@/utils/order-state-utils"
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"
import { PAY_STATE_MAP } from "@/utils/pay-state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px",
        scopedSlots: { customRender: "id" },
        sorter: (a, b) => b.id - a.id
      },
      {
        title: "时间",
        dataIndex: "tour_date",
        scopedSlots: { customRender: "tour_date" },
        sorter: (a, b) => {
          return (
            this.$moment(b.tour_date).unix() - this.$moment(a.tour_date).unix()
          )
        }
      },
      {
        title: "状态",
        dataIndex: "state",
        scopedSlots: { customRender: "state" }
      },
      {
        title: "出发",
        dataIndex: "set_out",
        scopedSlots: { customRender: "set_out" }
      },
      {
        title: "产品",
        dataIndex: "product",
        scopedSlots: { customRender: "product" }
      },
      {
        title: "联系人",
        dataIndex: "contacts",
        scopedSlots: { customRender: "contacts" }
      },
      {
        title: "管家",
        dataIndex: "butler_name",
        scopedSlots: { customRender: "butler_name" }
      },
      {
        title: "酒店",
        dataIndex: "hotel_name",
        scopedSlots: { customRender: "hotel_name" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      columns: columns,
      loading: false,
      state: undefined,
      type: undefined,
      tour_date: undefined,
      data: [],
      pagination: {}
    }
  },
  filters: {
    orderStateCode(state) {
      return ORDER_STATE_MAP[state].state
    },
    orderStateText(state) {
      return ORDER_STATE_MAP[state].text
    },
    payStateCode(state) {
      return PAY_STATE_MAP[state].state
    },
    payStateText(state) {
      return PAY_STATE_MAP[state].text
    },
    orderSourceText(code) {
      return ORDER_SOURCE_MAP[code].text
    },
    getDate(date) {
      let arr = date.split("-")
      return arr[1] + "-" + arr[2]
    }
  },
  computed: {
    isStaff() {
      return !(
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    }
  },
  activated() {
    if (this.$route.query.state) {
      this.state = Number(this.$route.query.state)
    }
    if (this.$route.query.type) {
      this.type = Number(this.$route.query.type)
    }
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  methods: {
    inservice(date) {
      let now = this.$moment().unix()
      let tour_date = this.$moment(date).unix()
      if (now > tour_date) {
        return { text: "进行中", color: "geekblue" }
      } else {
        return { text: "待服务", color: ORDER_STATE_MAP[3].state }
      }
    },
    selectChange() {
      const pager = { ...this.pagination }
      pager.current = 1
      this.pagination = pager
      this.getData({
        page: this.pagination.current,
        size: this.pagination.pageSize
      })
    },
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = config.PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getOrderList",
        method: "get",
        params: {
          page: page,
          size: size,
          state: this.state,
          type: this.type,
          tour_date: this.tour_date
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
      })
    },
    showCancelDialog(id) {
      this.$modal.confirm({
        title: "系统提示",
        content: "您确定要取消该订单吗?",
        onOk: () => {
          this.cancel(id)
        }
      })
    },
    cancel(id) {
      this.$request({
        url: "/order",
        method: "delete",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data[index].state = 0
        this.$message.success(res.message)
      })
    }
  }
}
</script>
<style>
.ant-card {
  min-height: 100%;
}

.red {
  color: red;
}

.page-select {
  width: 180px;
  margin-left: 30px;
}

.page-table-info {
  font-size: 12px;
}

.page-table-badge span {
  font-size: 12px;
}

.page-table-date {
  color: rgba(255, 30, 0, 0.945);
}
</style>
