<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-select
      slot="extra"
      v-model="state"
      placeholder="账单状态"
      @change="selectChange"
      class="page-select"
    >
      <a-select-option v-if="state != undefined" :value="undefined"
        >全部</a-select-option
      >
      <a-select-option :value="0">未支付</a-select-option>
      <a-select-option :value="3">已支付</a-select-option>
    </a-select>
    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <span slot="state" slot-scope="scope">
        <a-badge :color="scope | payStateCode" :text="scope | payStateText" />
      </span>
      <span slot="travel_agency_name" slot-scope="scope">
        <a-tag color="red">{{ scope }}</a-tag>
      </span>
      <span slot="number" slot-scope="scope">
        <a-tag color="red">{{ scope }} 个</a-tag>
      </span>
      <span slot="total_price" slot-scope="scope" class="red"
        >¥{{ scope }}</span
      >
      <span slot="action" slot-scope="scope">
        <router-link :to="{ path: 'bill-order', query: { id: scope.id } }"
          >订单信息</router-link
        >
        <span v-if="isAdmin">
          <a-divider type="vertical" />
          <a-popconfirm
            placement="bottomRight"
            title="您确定已经收到了旅行社的转款?"
            @confirm="confirm(scope.id)"
          >
            <a :disabled="scope.state !== 0">确认收款</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="bottomRight"
            title="您确定要撤销该账单?"
            @confirm="cancel(scope.id)"
          >
            <a :disabled="scope.state !== 0">撤销账单</a>
          </a-popconfirm>
        </span>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import * as config from "@/config"
import { PAY_STATE_MAP } from "@/utils/pay-state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id"
      },
      {
        title: "状态",
        dataIndex: "state",
        scopedSlots: { customRender: "state" }
      },
      {
        title: "账单日期",
        dataIndex: "date"
      },
      {
        title: "旅行社",
        dataIndex: "travel_agency_name",
        scopedSlots: { customRender: "travel_agency_name" }
      },
      {
        title: "订单数",
        dataIndex: "number",
        scopedSlots: { customRender: "number" }
      },
      {
        title: "账单金额",
        dataIndex: "total_price",
        scopedSlots: { customRender: "total_price" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      columns: columns,
      loading: false,
      data: [],
      pagination: {},
      state: undefined
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getState.permission == config.PERMISSION_ADMIN
    }
  },
  activated() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  filters: {
    payStateCode(state) {
      return PAY_STATE_MAP[state].state
    },
    payStateText(state) {
      return PAY_STATE_MAP[state].text
    }
  },
  methods: {
    selectChange() {
      const pager = { ...this.pagination }
      pager.current = 1
      this.pagination = pager
      this.getData({
        page: this.pagination.current,
        size: this.pagination.pageSize
      })
    },
    confirm(id) {
      this.$request({
        url: "/confirmReceipt",
        method: "post",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data[index].state = 3
        this.$message.success(res.message)
      })
    },
    cancel(id) {
      this.$request({
        url: "/cancelBill",
        method: "post",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data.splice(index, 1)
        this.$message.success(res.message)
      })
    },
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = config.PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getBillList",
        method: "get",
        params: {
          page: page,
          size: size,
          state: this.state
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.ant-card {
  min-height: 100%;
}

.page-select {
  width: 180px;
  margin-left: 30px;
}

.red {
  color: red;
}
</style>
