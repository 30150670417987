<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a slot="extra" v-if="isEdit && current === 0" @click="$router.go(-1)"
      >返回</a
    >
    <a-steps class="page-steps" :current="current" size="small">
      <a-step title="填写订单信息" />
      <a-step title="完善游客信息" />
      <a-step title="录入完成" />
    </a-steps>
    <div class="page-content">
      <step1 v-if="current === 0" @next="next" :order_id="order_id" />
      <step2
        v-if="current === 1"
        @complete="complete"
        @goBack="goBack"
        :order_id="order_id"
      />
      <step3 v-if="current === 2" :order_id="order_id" />
    </div>
  </a-card>
</template>

<script>
import * as types from "@/store/vuex-types"
import Step1 from "./step/Step1"
import Step2 from "./step/Step2"
import Step3 from "./step/Step3"

export default {
  components: {
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      current: 0,
      order_id: undefined
    }
  },
  computed: {
    isEdit() {
      return this.$route.path === "/order/edit"
    }
  },
  mounted() {
    this.$store.commit(types.CLEAR_VISITORS)
    if (this.$route.path === "/order/edit") {
      if (this.$route.query.id) {
        this.order_id = this.$route.query.id
      } else {
        this.$router.replace("/order/list")
      }
    } else {
      this.loading = false
    }
    this.goTop()
  },
  methods: {
    next(order_id) {
      this.order_id = order_id
      this.current = 1
      this.goTop()
    },
    goBack() {
      this.current = 0
      this.goTop()
    },
    complete() {
      this.current = 2
      this.goTop()
    },
    goTop() {
      //返回浏览器顶部
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop
      let step = distance / 30
      ;(function jump() {
        if (distance > 0) {
          distance -= step
          window.scrollTo(0, distance)
          setTimeout(jump, 10)
        }
      })()
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.page-content {
  margin: 0px 30px 50px 30px;
}

.page-steps {
  max-width: 700px;
  margin: 30px auto;
}
</style>
