<template>
  <a-card
    :title="$route.meta.title + ' ID:' + $route.query.id"
    :bordered="false"
    hoverable
  >
    <a slot="extra" @click="$router.go(-1)">返回</a>
    <a-spin :spinning="spinning">
      <div class="page">
        <OrderInfoView :orderData="orderData" />
        <!--form-->
        <a-row :gutter="30">
          <a-col :span="12"
            ><a-form-model
              ref="iform1"
              :model="formData"
              :rules="rules"
              @submit="changeDateSubmit"
              @submit.native.prevent
            >
              <a-form-model-item
                label="更改日期"
                prop="tour_date"
                :extra="tourDateExtra"
              >
                <a-date-picker
                  class="page-input-width"
                  v-model="formData.tour_date"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  placeholder="请选择游玩日期"
                  :disabled-date="disabledDate"
                  :disabled="productInfo.holiday === 1"
                />
              </a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                class="page-button"
                :loading="loading"
                :disabled="productInfo.holiday === 1"
              >
                更改日期
              </a-button>
            </a-form-model>
          </a-col>
          <a-col :span="12"
            ><a-form-model
              ref="iform2"
              :model="formData"
              :rules="rules"
              @submit="cancelSubmit"
              @submit.native.prevent
            >
              <a-form-model-item
                label="取消订单"
                prop="cost"
                :extra="returnExtra"
              >
                <a-input-number
                  class="page-input-width"
                  v-model="formData.cost"
                  :min="0"
                  placeholder="请输入取消费用"
                />
              </a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                class="page-button"
                :loading="loading"
              >
                取消订单
              </a-button>
            </a-form-model>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </a-card>
</template>

<script>
import OrderInfoView from "@/components/OrderInfoView.vue"

export default {
  components: { OrderInfoView },
  data() {
    const rules = {
      tour_date: [
        {
          required: true,
          message: "请设置更改日期",
          trigger: "blur"
        }
      ],
      cost: [
        {
          required: true,
          message: "请输入取消费用",
          trigger: "blur"
        },
        {
          pattern: /^\d+$/,
          message: "费用必须为整数金额",
          trigger: "blur"
        }
      ]
    }

    return {
      rules: rules,
      spinning: true,
      loading: false,
      orderData: {},
      orderInfo: {},
      productInfo: {},
      formData: {
        id: undefined,
        tour_date: undefined,
        cost: 0
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.formData.id = this.$route.query.id
      this.$request({
        url: "/getOrderInfo",
        method: "get",
        params: {
          id: this.$route.query.id
        }
      }).then(res => {
        this.orderData = res.data
        this.productInfo = this.orderData.product_info
        this.orderInfo = this.orderData.order_info
        this.formData.tour_date = this.orderInfo.tour_date
        if (this.productInfo.change_time > 0) {
          let tour_date = this.$moment(this.orderInfo.tour_date)
          let now = this.$moment().format("YYYY-MM-DD")
          if (tour_date.diff(now, "days") < this.productInfo.change_time) {
            if (this.orderInfo.type === 1) {
              this.formData.cost = this.orderInfo.deposit
            } else {
              this.formData.cost = this.orderInfo.people_number * 100
            }
          }
        }
        this.spinning = false
      })
    } else {
      this.$router.go(-1)
    }
  },
  computed: {
    tourDateExtra() {
      if (this.productInfo.start_date && this.productInfo.end_date) {
        return (
          "产品有效时间: " +
          this.productInfo.start_date +
          " ~ " +
          this.productInfo.end_date
        )
      } else {
        return undefined
      }
    }
  },
  methods: {
    returnExtra() {
      if (this.productInfo.change_time > 0) {
        let tour_date = this.$moment(this.orderInfo.tour_date)
        let now = this.$moment().format("YYYY-MM-DD")
        if (tour_date.diff(now, "days") < this.productInfo.change_time) {
          return "已超过退改时间！需支付退改费用"
        } else {
          return "未超过退改时间,0退改费用！"
        }
      } else {
        return "订单随时退改,0退改费用！"
      }
    },
    disabledDate(current) {
      if (this.productInfo && current) {
        return (
          current < this.$moment(this.productInfo.start_date) ||
          current > this.$moment(this.productInfo.end_date).endOf("day") ||
          current < this.$moment().startOf("day")
        )
      }
      return true
    },
    changeDateSubmit() {
      this.$refs.iform1.validate(valid => {
        if (valid) {
          this.loading = true
          this.$request({
            url: "/changeDate",
            method: "put",
            data: {
              id: this.formData.id,
              tour_date: this.formData.tour_date
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.go(-1)
              }, 2000)
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    cancelSubmit() {
      this.$refs.iform2.validate(valid => {
        if (valid) {
          this.$modal.confirm({
            title: "系统提示",
            content: "您确定要取消该订单吗?",
            onOk: () => {
              this.loading = true
              this.$request({
                url: "/cancelOrder",
                method: "put",
                data: {
                  id: this.formData.id,
                  cost: this.formData.cost
                }
              })
                .then(res => {
                  this.$message.success(res.message)
                  setTimeout(() => {
                    this.$router.go(-1)
                  }, 2000)
                })
                .catch(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.page {
  padding: 20px;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-input-width {
  width: 50%;
  min-width: 300px;
}

.page-button {
  width: 30%;
  margin-bottom: 30px;
  float: left;
}

.warning {
  color: rgb(245, 78, 0);
}
</style>
