export function birthdayToAge(strBirthday) {
  let returnAge
  let strBirthdayArr = strBirthday.split("-")
  let birthYear = strBirthdayArr[0]
  let birthMonth = strBirthdayArr[1]
  let birthDay = strBirthdayArr[2]
  let date = new Date()
  let nowYear = date.getFullYear()
  let nowMonth = date.getMonth() + 1
  let nowDay = date.getDate()
  if (nowYear == birthYear) {
    returnAge = 0 //同年 则为0岁
  } else {
    let ageDiff = nowYear - birthYear //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        let dayDiff = nowDay - birthDay //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      } else {
        let monthDiff = nowMonth - birthMonth //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      }
    } else {
      returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
    }
  }
  return returnAge //返回周岁年龄
}

export function idToAge(idcard, date = undefined) {
  if (!idcard) {
    return undefined
  }
  //获取出生年月日
  let yearBirth = idcard.substring(6, 10)
  let monthBirth = idcard.substring(10, 12)
  let dayBirth = idcard.substring(12, 14)
  //获取当前年月日并计算年龄
  let monthNow = undefined
  let dayNow = undefined
  let age = undefined
  if (date) {
    const arr = date.split("-")
    monthNow = arr[1]
    dayNow = arr[2]
    age = arr[0] - yearBirth
  } else {
    const myDate = new Date()
    monthNow = myDate.getMonth() + 1
    dayNow = myDate.getDay()
    age = myDate.getFullYear() - yearBirth
  }
  if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
    age--
  }
  return age
}

export function idToSex(idcard) {
  if (!idcard) {
    return undefined
  }
  let sex
  //获取性别
  if (parseInt(idcard.substr(16, 1)) % 2 == 1) {
    sex = 1
  } else {
    sex = 0
  }
  return sex
}
