<template>
  <a-spin :spinning="spinning">
    <a-card :title="$route.meta.title + ' ID:' + $route.query.id" hoverable>
      <a slot="extra" @click="$router.go(-1)">返回</a>
      <div class="content">
        <OrderInfoView :orderData="orderData" />
      </div>
    </a-card>
    <a-row :gutter="10">
      <a-col :span="6">
        <a-card title="修改订单金额" hoverable>
          <div class="content">
            <a-form-model ref="priceForm" :model="formData" :rules="rules">
              <a-form-model-item label="修改金额" prop="total_price">
                <a-input-number
                  class="page-input-number"
                  v-model="formData.total_price"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                />
              </a-form-model-item>
              <a-form-model-item label="修改原因" prop="price_remark">
                <a-input
                  v-model="formData.price_remark"
                  type="text"
                  placeholder="请输入修改原因"
                />
              </a-form-model-item>
              <a-button
                type="primary"
                class="page-button"
                :loading="loading"
                @click="changePrice"
              >
                提交修改
              </a-button>
            </a-form-model>
          </div>
        </a-card>
        <a-card title="修改支付状态" hoverable v-if="orderInfo.type == 1">
          <div class="content">
            <a-form-model>
              <a-select v-model="formData.pay_state">
                <a-select-option :value="0">未支付</a-select-option>
                <a-select-option :value="1">已支付定金</a-select-option>
                <a-select-option :value="3">已支付全款</a-select-option>
              </a-select>
              <a-button
                type="primary"
                class="page-button"
                :loading="loading"
                @click="changePay"
              >
                提交修改
              </a-button>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
      <a-col :span="10"
        ><a-card title="订单费用确认" hoverable>
          <div class="content">
            <a-form-model
              ref="costForm"
              :model="orderCost"
              :rules="rules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-form-model-item label="订单利润">
                <span class="price">¥ {{ profit }}</span>
              </a-form-model-item>
              <a-form-model-item label="住宿费用" prop="cost_hotel">
                <a-input-number
                  class="page-input-number"
                  v-model="orderCost.cost_hotel"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                  :disabled="productInfo.days === 0"
                />
              </a-form-model-item>
              <a-form-model-item label="交通费用" prop="cost_car">
                <a-input-number
                  class="page-input-number"
                  v-model="orderCost.cost_car"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                />
              </a-form-model-item>
              <a-form-model-item label="景区门票" prop="cost_ticket">
                <a-input-number
                  class="page-input-number"
                  v-model="orderCost.cost_ticket"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                />
              </a-form-model-item>
              <a-form-model-item label="海上项目" prop="cost_sea">
                <a-input-number
                  class="page-input-number"
                  v-model="orderCost.cost_sea"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                />
              </a-form-model-item>
              <a-form-model-item
                label="岛外交通"
                prop="cost_ship"
                extra="进出岛船票,岛外叫车等费用"
              >
                <a-input-number
                  class="page-input-number"
                  v-model="orderCost.cost_ship"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                />
              </a-form-model-item>
              <a-form-model-item
                v-if="orderInfo.type === 3"
                label="分销返点"
                prop="cost_profit"
              >
                <a-input-number
                  class="page-input-number"
                  v-model="orderCost.cost_profit"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                />
              </a-form-model-item>
              <a-form-model-item label="其他费用" prop="cost_other">
                <a-input-number
                  class="page-input-number"
                  v-model="orderCost.cost_other"
                  :min="0"
                  :step="0.01"
                  placeholder="请输入金额"
                />
              </a-form-model-item>
              <a-button
                type="primary"
                class="page-button"
                size="large"
                :loading="loading"
                @click="completeOrder"
              >
                完成订单
              </a-button>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
      <a-col :span="8"
        ><a-card title="操作日志" hoverable>
          <div class="content">
            <a-timeline>
              <a-timeline-item
                v-for="(log, index) in logs"
                :key="index"
                color="green"
              >
                <p>{{ log.info }}</p>
                <p class="page-logs-exp">
                  操作用户:{{ log.username }} 时间:{{ log.time }}
                </p>
              </a-timeline-item>
            </a-timeline>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import OrderInfoView from "@/components/OrderInfoView.vue"

export default {
  components: { OrderInfoView },
  data() {
    const rules = {
      total_price: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ],
      price_remark: [
        {
          required: true,
          message: "请输入修改原因",
          trigger: "blur"
        },
        {
          max: 20,
          message: "不要超过20字符",
          trigger: "blur"
        }
      ],
      cost_hotel: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ],
      cost_car: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ],
      cost_ticket: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ],
      cost_sea: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ],
      cost_ship: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ],
      cost_profit: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ],
      cost_other: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ]
    }

    return {
      rules: rules,
      spinning: true,
      loading: false,
      orderData: {},
      orderInfo: {},
      orderCost: {},
      productInfo: {},
      logs: [],
      formData: {
        id: undefined,
        total_price: undefined,
        price_remark: undefined,
        pay_state: undefined
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.formData.id = this.$route.query.id
      let request1 = this.$request({
        url: "/getOrderInfo",
        method: "get",
        params: {
          id: this.formData.id
        }
      })
      let request2 = this.$request({
        url: "/getOrderCost",
        method: "get",
        params: {
          id: this.formData.id
        }
      })
      let request3 = this.$request({
        url: "/getOrderLogs",
        method: "get",
        params: {
          id: this.formData.id
        }
      })
      Promise.all([request1, request2, request3]).then(res => {
        this.orderData = res[0].data
        this.orderInfo = this.orderData.order_info
        this.productInfo = this.orderData.product_info
        this.formData.pay_state = this.orderInfo.pay_state
        this.orderCost = res[1].data
        this.logs = res[2].data
        this.spinning = false
      })
    } else {
      this.$router.go(-1)
    }
  },
  computed: {
    profit() {
      let profit = 0
      if (this.orderCost && this.orderInfo) {
        profit =
          this.orderInfo.total_price -
          this.orderCost.cost_hotel -
          this.orderCost.cost_car -
          this.orderCost.cost_ticket -
          this.orderCost.cost_sea -
          this.orderCost.cost_ship -
          this.orderCost.cost_profit -
          this.orderCost.cost_other
      }
      return profit
    }
  },
  methods: {
    changePrice() {
      this.$refs.priceForm.validate(valid => {
        if (valid) {
          let content =
            "您确定要将价格修改为" + this.formData.total_price + "元吗?"
          this.$modal.confirm({
            title: "系统提示",
            content: content,
            onOk: () => {
              this.loading = true
              this.$request({
                url: "/changeOrderPrice",
                method: "post",
                data: this.formData
              })
                .then(res => {
                  this.$message.success(res.message)
                  setTimeout(() => {
                    this.$router.go(0)
                  }, 2000)
                })
                .catch(() => {
                  this.loading = false
                })
            },
            onCancel() {}
          })
        }
      })
    },
    changePay() {
      this.$modal.confirm({
        title: "系统提示",
        content: "您确定要该订单的修改支付状态?",
        onOk: () => {
          this.loading = true
          this.$request({
            url: "/changePayState",
            method: "post",
            data: {
              id: this.formData.id,
              pay_state: this.formData.pay_state
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.go(0)
              }, 2000)
            })
            .catch(() => {
              this.loading = false
            })
        },
        onCancel() {}
      })
    },
    completeOrder() {
      this.$refs.costForm.validate(valid => {
        if (valid) {
          if (this.orderInfo.type == 1 && this.orderInfo.pay_state != 3) {
            this.$modal.confirm({
              title: "系统提示",
              content: "您确定客人已经支付了全部费用?",
              onOk: () => {
                this.complete()
              },
              onCancel() {}
            })
          } else {
            this.complete()
          }
        }
      })
    },
    complete() {
      this.loading = true
      this.$request({
        url: "/completeOrder",
        method: "post",
        data: this.orderCost
      })
        .then(res => {
          this.$message.success(res.message)
          setTimeout(() => {
            this.$router.go(-1)
          }, 2000)
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
  margin-bottom: 10px;
}

.content {
  padding: 20px;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-input-number {
  width: 100%;
}

.page-button {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.price {
  font-weight: bold;
  color: red;
}

.warning {
  color: rgb(245, 78, 0);
}

.page-logs-exp {
  font-size: 12px;
}
</style>
