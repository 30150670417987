<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <span slot="extra">
      <span class="page-balance-text"
        >剩余金额:
        <span
          :class="
            balance > 0
              ? 'page-balance-amount page-amount-red'
              : 'page-balance-amount page-amount-green'
          "
          >¥ {{ balance }}
        </span></span
      >
      <a-button
        v-if="isAdmin"
        type="primary"
        :disabled="!isEnable"
        :loading="loading"
        @click="handleSubmit"
      >
        确认提交
      </a-button></span
    >
    <a-modal
      title="账目未初始化"
      :visible="modal.visible"
      :confirm-loading="modal.loading"
      :closable="false"
      :maskClosable="false"
      okText="初始化"
      cancelText="返回"
      @ok="modalOk"
      @cancel="modalCancel"
    >
      <a-input-number
        class="page-input-number"
        v-model="modal.amount"
        :min="0"
        :step="0.01"
        placeholder="请输入初始资金"
      />
    </a-modal>
    <a-spin :spinning="spinning">
      <a-form-model
        class="page-content"
        ref="iform"
        :model="formData"
        :rules="rules"
      >
        <a-row :gutter="20" v-if="isAdmin">
          <a-col :span="4">
            <a-form-model-item label="类型">
              <a-radio-group
                button-style="solid"
                v-model="formData.type"
                @change="typeChange"
              >
                <a-radio-button :value="1">
                  支出
                </a-radio-button>
                <a-radio-button :value="2">
                  收入
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item v-if="formData.type == 1" label="用途">
              <a-select v-model="formData.desc" placeholder="请选择支出用途">
                <a-select-option v-for="key in 6" :value="key" :key="key">{{
                  PURP0SE_MAP[key]
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-else label="来源">
              <a-select v-model="formData.desc" placeholder="请选择收入来源">
                <a-select-option v-for="key in 6" :value="key" :key="key">{{
                  SOURCE_MAP[key]
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="金额" prop="amount">
              <a-input-number
                class="page-input-number"
                v-model="formData.amount"
                :min="0"
                :step="0.01"
                placeholder="请输入金额"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item label="备注信息" prop="remark">
              <a-input
                v-model="formData.remark"
                type="text"
                placeholder="请输入备注信息"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <!--Table-->
        <a-form-model-item label="收支明细">
          <a-table
            rowKey="id"
            :columns="columns"
            :data-source="data.data"
            :pagination="pagination"
            @change="pageChange"
          >
            <span slot="type" slot-scope="scope">
              <a-tag v-if="scope === 1" color="green">支出</a-tag>
              <a-tag v-else-if="scope === 2" color="red">收入</a-tag>
              <a-tag v-else>删除</a-tag>
            </span>
            <span slot="desc" slot-scope="scope, record">
              <a-tag v-if="record.type === 1" color="green">{{
                PURP0SE_MAP[scope]
              }}</a-tag>
              <a-tag v-else-if="record.type === 2" color="red">{{
                SOURCE_MAP[scope]
              }}</a-tag>
              <a-tag v-else>{{ SOURCE_MAP[scope] }}</a-tag>
            </span>
            <span slot="amount" slot-scope="scope, record">
              <span v-if="record.type === 1" class="page-amount-green"
                >¥ {{ scope }}</span
              >
              <span v-else-if="record.type === 2" class="page-amount-red"
                >¥ {{ scope }}</span
              >
              <span v-else>¥ {{ scope }}</span>
            </span>
            <span slot="remark" slot-scope="scope">
              <a-tooltip>
                <template slot="title">
                  {{ scope }}
                </template>
                {{ scope }}
              </a-tooltip>
            </span>
            <span slot="action" slot-scope="scope, record">
              <a-popconfirm
                v-if="record.type !== 3"
                placement="bottomRight"
                title="您确定要删除该账单吗?"
                @confirm="remove(scope.id)"
              >
                <a :disabled="!isAdmin">删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-card>
</template>

<script>
import * as config from "@/config"
import { ACCOUNT_PURP0SE_MAP, ACCOUNT_SOURCE_MAP } from "@/utils/option-utils"

export default {
  data() {
    const columns = [
      {
        title: "日期",
        dataIndex: "date"
      },
      {
        title: "分类",
        dataIndex: "type",
        scopedSlots: { customRender: "type" }
      },
      {
        title: "用途/来源",
        dataIndex: "desc",
        scopedSlots: { customRender: "desc" }
      },
      {
        title: "金额",
        dataIndex: "amount",
        scopedSlots: { customRender: "amount" }
      },
      {
        title: "备注",
        dataIndex: "remark",
        ellipsis: true,
        width: "300px",
        scopedSlots: { customRender: "remark" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]

    const rules = {
      remark: [
        {
          max: 100,
          message: "备注不要超过100字符",
          trigger: "blur"
        }
      ],
      amount: [
        {
          required: true,
          message: "请输入金额",
          trigger: "blur"
        }
      ]
    }

    return {
      spinning: false,
      isEnable: false,
      loading: false,
      data: [],
      pagination: {},
      columns: columns,
      rules: rules,
      balance: "******",
      formData: {
        type: 1,
        desc: 1,
        amount: undefined,
        remark: undefined
      },
      modal: {
        visible: false,
        loading: false,
        amount: undefined
      },
      PURP0SE_MAP: ACCOUNT_PURP0SE_MAP,
      SOURCE_MAP: ACCOUNT_SOURCE_MAP
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getState.permission === config.PERMISSION_ADMIN
    }
  },
  mounted() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  methods: {
    typeChange() {
      this.formData.desc = 1
      this.formData.amount = undefined
      this.formData.remark = undefined
    },
    remove(id) {
      this.$request({
        url: "/accountBook",
        method: "delete",
        data: {
          id: id
        }
      }).then(res => {
        this.$message.success(res.message)
        this.getData({
          page: this.pagination.current,
          size: this.pagination.pageSize
        })
      })
    },
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = config.PAGE_SIZE } = {}) {
      this.spinning = true
      this.isEnable = false
      this.$request({
        url: "/getAccountBookList",
        method: "get",
        params: {
          page: page,
          size: size
        }
      }).then(res => {
        this.data = res.data.list
        const pagination = { ...this.pagination }
        pagination.total = res.data.list.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.spinning = false
        this.isEnable = true
        if (res.data.balance === null) {
          this.modal.visible = true
        } else {
          this.balance = res.data.balance
        }
      })
    },
    modalOk() {
      if (this.modal.amount != null) {
        this.modal.loading = true
        this.$request({
          url: "/initAccountBook",
          method: "post",
          data: {
            amount: this.modal.amount
          }
        })
          .then(res => {
            this.$message.success(res.message)
            this.modal.loading = false
            this.modal.visible = false
            this.getData({
              page: this.pagination.current,
              size: this.pagination.pageSize
            })
          })
          .catch(() => {
            this.modal.loading = false
          })
      }
    },
    modalCancel() {
      this.$router.replace("/")
    },
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.loading = true
          this.$request({
            url: "/accountBook",
            method: "post",
            data: {
              type: this.formData.type,
              desc: this.formData.desc,
              amount: this.formData.amount,
              remark: this.formData.remark
            }
          })
            .then(res => {
              this.$message.success(res.message)
              this.loading = false
              this.formData.amount = undefined
              this.formData.remark = undefined
              this.getData({
                page: 1,
                size: this.pagination.pageSize
              })
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-content {
  padding: 20px;
}

.page-input-number {
  width: 100%;
}

.page-amount-red {
  color: red;
}

.page-amount-green {
  color: green;
}

.page-balance-text {
  margin-right: 60px;
  font-size: 12px;
}

.page-balance-amount {
  font-weight: 700;
  margin-left: 5px;
}
</style>
