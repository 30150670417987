<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <span slot="extra">
      <span class="page-balance-text"
        >住宿加交通:
        <span class="page-balance-amount page-amount-red"
          >¥ {{ totalAmount1 }}
        </span></span
      >
      <span class="page-balance-text"
        >不含海上项目:
        <span class="page-balance-amount page-amount-red"
          >¥ {{ totalAmount2 }}
        </span></span
      >
      <span class="page-balance-text"
        >结算总金额:
        <span class="page-balance-amount page-amount-red"
          >¥ {{ totalAmount3 }}
        </span></span
      >
    </span>
    <a-modal
      :title="modalTitle"
      :visible="modal.visible"
      :confirm-loading="modal.loading"
      :closable="false"
      :maskClosable="false"
      okText="确认结算"
      cancelText="取消"
      @ok="modalOk"
      @cancel="modalCancel"
    >
      <div>
        <a-checkbox
          v-model="modal.check0"
          :disabled="modal.order.cost_ticket <= 0"
          >景区门票转账给管家</a-checkbox
        >
      </div>
      <div class="page-modal-item">
        <a-checkbox v-model="modal.check1" :disabled="modal.order.cost_sea <= 0"
          >海上项目转账给管家</a-checkbox
        >
      </div>
      <div class="page-modal-item">
        <a-checkbox
          v-model="modal.check2"
          :disabled="modal.order.cost_other <= 0"
          >其他费用转账给管家</a-checkbox
        >
      </div>
    </a-modal>
    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <a-tooltip slot="id" slot-scope="scope, record">
        <template slot="title">
          查看订单详情
        </template>
        <router-link :to="{ path: '/order/info', query: { id: record.id } }">{{
          scope
        }}</router-link>
      </a-tooltip>
      <span slot="tour_date" slot-scope="scope">
        {{ scope }}
      </span>
      <a-tooltip slot="contacts" slot-scope="scope, record">
        <template slot="title"> TEL: {{ record.mobile }} </template>
        {{ scope }}
        <a-tag color="red">{{
          record.people_number + record.child_number
        }}</a-tag>
      </a-tooltip>
      <span slot="cost_hotel" slot-scope="scope" class="red"
        >¥ {{ scope }}</span
      >
      <span slot="cost_car" slot-scope="scope" class="red">¥ {{ scope }}</span>
      <span slot="cost_ticket" slot-scope="scope" class="red"
        >¥{{ scope }}</span
      >
      <span slot="cost_sea" slot-scope="scope" class="red">¥ {{ scope }}</span>
      <span slot="cost_ship" slot-scope="scope" class="red">¥ {{ scope }}</span>
      <span slot="cost_other" slot-scope="scope" class="red"
        >¥ {{ scope }}</span
      >
      <span slot="action" slot-scope="scope">
        <a @click="settlement(scope)">线上</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="bottomRight"
          title="您确定已经在线下结算了吗?"
          @confirm="offline(scope.id)"
        >
          <a>线下</a>
        </a-popconfirm>
      </span>
      <div slot="expandedRowRender" slot-scope="record" class="page-table-info">
        <span
          >{{ record.people_number }}人
          <span v-if="record.child_number > 0">
            +{{ record.child_number }}幼儿
          </span>
          <a-divider type="vertical"
        /></span>
        <span v-if="record.travel_agency_name"
          >{{ record.travel_agency_name }}<a-divider type="vertical"
        /></span>
        <span v-if="record.source"
          >{{ record.source | orderSourceText }}<a-divider type="vertical"
        /></span>
        <span v-if="record.create_time"
          >创建时间: {{ record.create_time }}<a-divider type="vertical"
        /></span>
        <span v-if="record.pay_state === 1"
          >定金: ¥{{ record.deposit }}<a-divider type="vertical"
        /></span>
        <span>订单总价: ¥{{ record.total_price }}</span>
      </div>
    </a-table>
  </a-card>
</template>

<script>
import * as config from "@/config"
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px",
        scopedSlots: { customRender: "id" },
        sorter: (a, b) => b.id - a.id
      },
      {
        title: "时间",
        dataIndex: "tour_date",
        scopedSlots: { customRender: "tour_date" },
        sorter: (a, b) => {
          return (
            this.$moment(b.tour_date).unix() - this.$moment(a.tour_date).unix()
          )
        }
      },
      {
        title: "联系人",
        dataIndex: "contacts",
        scopedSlots: { customRender: "contacts" }
      },
      {
        title: "酒店",
        dataIndex: "cost_hotel",
        scopedSlots: { customRender: "cost_hotel" }
      },
      {
        title: "交通",
        dataIndex: "cost_car",
        scopedSlots: { customRender: "cost_car" }
      },
      {
        title: "岛外交通",
        dataIndex: "cost_ship",
        scopedSlots: { customRender: "cost_ship" }
      },
      {
        title: "门票",
        dataIndex: "cost_ticket",
        scopedSlots: { customRender: "cost_ticket" }
      },
      {
        title: "海上项目",
        dataIndex: "cost_sea",
        scopedSlots: { customRender: "cost_sea" }
      },
      {
        title: "其他",
        dataIndex: "cost_other",
        scopedSlots: { customRender: "cost_other" }
      },
      {
        title: "结算",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      columns: columns,
      loading: false,
      data: [],
      pagination: {},
      totalAmount1: 0,
      totalAmount2: 0,
      totalAmount3: 0,
      modal: {
        visible: false,
        loading: false,
        check0: false,
        check1: false,
        check2: false,
        order: {}
      }
    }
  },
  filters: {
    orderSourceText(code) {
      return ORDER_SOURCE_MAP[code].text
    }
  },
  activated() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  computed: {
    modalTitle() {
      return (
        "结算明细 订单ID:" +
        this.modal.order.id +
        " " +
        this.modal.order.contacts
      )
    }
  },
  methods: {
    settlement(record) {
      this.modal.order = record
      this.modal.check0 = false
      this.modal.check1 = false
      this.modal.check2 = false
      this.modal.visible = true
    },
    offline(id) {
      this.$request({
        url: "/offlineSettlement",
        method: "post",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data.splice(index, 1)
        this.$message.success(res.message)
        this.calculationTotalAmount()
      })
    },
    modalOk() {
      this.modal.visible = false
      const id = this.modal.order.id
      this.$request({
        url: "/settlement",
        method: "post",
        data: {
          id: id,
          check0: this.modal.check0,
          check1: this.modal.check1,
          check2: this.modal.check2
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data.splice(index, 1)
        this.$message.success(res.message)
        this.calculationTotalAmount()
      })
    },
    modalCancel() {
      this.modal.visible = false
    },
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = config.PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getOrderList",
        method: "get",
        params: {
          page: page,
          size: size,
          state: 4,
          pay_state: 3
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
        this.calculationTotalAmount()
      })
    },
    calculationTotalAmount() {
      let totalAmount1 = 0
      let totalAmount2 = 0
      let totalAmount3 = 0
      for (let i = 0; i < this.data.data.length; i++) {
        totalAmount1 += Number(this.data.data[i].cost_hotel)
        totalAmount1 += Number(this.data.data[i].cost_car)
        totalAmount1 += Number(this.data.data[i].cost_ship)
        totalAmount2 += Number(this.data.data[i].cost_hotel)
        totalAmount2 += Number(this.data.data[i].cost_car)
        totalAmount2 += Number(this.data.data[i].cost_ticket)
        totalAmount2 += Number(this.data.data[i].cost_ship)
        totalAmount3 += Number(this.data.data[i].cost_hotel)
        totalAmount3 += Number(this.data.data[i].cost_car)
        totalAmount3 += Number(this.data.data[i].cost_ticket)
        totalAmount3 += Number(this.data.data[i].cost_sea)
        totalAmount3 += Number(this.data.data[i].cost_ship)
        totalAmount3 += Number(this.data.data[i].cost_other)
      }
      this.totalAmount1 = totalAmount1
      this.totalAmount2 = totalAmount2
      this.totalAmount3 = totalAmount3
    }
  }
}
</script>
<style>
.ant-card {
  min-height: 100%;
}

.ant-row {
  margin-bottom: 5px;
}

.red {
  color: red;
}

.page-table-info {
  font-size: 12px;
}

.page-amount-red {
  color: red;
}

.page-balance-text {
  margin-right: 20px;
  font-size: 12px;
}

.page-balance-amount {
  font-weight: 700;
  margin-left: 5px;
}

.page-modal-item {
  margin-top: 20px;
}
</style>
