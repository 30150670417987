<template>
  <a-spin :spinning="spinning">
    <div class="page">
      <a-form-model
        ref="iform"
        :model="formData"
        :rules="rules"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="公告标题" prop="notice_title">
          <a-input
            v-model="formData.notice_title"
            type="text"
            placeholder="请输入公告标题"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="公告内容" prop="notice_content">
          <a-textarea
            v-model="formData.notice_content"
            :auto-size="{ minRows: 3, maxRows: 6 }"
            placeholder="请输入公告内容"
          />
        </a-form-model-item>
        <a-form-model-item label="结束时间" prop="notice_end_date">
          <a-date-picker
            v-model="formData.notice_end_date"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            :disabled-date="disabledDate"
            placeholder="请设置结束时间"
          />
        </a-form-model-item>
        <a-form-model-item
          label="开启公告"
          labelAlign="left"
          :labelCol="{ span: 6 }"
          :wrapperCol="{ span: 18 }"
        >
          <a-switch v-model="formData.notice_show" />
        </a-form-model-item>
        <a-form-model-item class="page-submit">
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            :disabled="!isEnable"
            :loading="!isEnable"
          >
            确认提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
export default {
  data() {
    return {
      spinning: true,
      isEnable: true,
      formData: {
        notice_title: undefined,
        notice_content: undefined,
        notice_end_date: undefined,
        notice_show: false
      },
      rules: {
        notice_title: [
          {
            required: true,
            message: "请输入公告标题",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请输入有效的公告标题",
            trigger: "blur"
          },
          {
            max: 30,
            message: "请输入有效的公告标题",
            trigger: "blur"
          }
        ],
        notice_content: [
          {
            required: true,
            message: "请输入公告内容",
            trigger: "blur"
          },
          {
            min: 10,
            message: "公告内容最少10字符",
            trigger: "blur"
          },
          {
            max: 100,
            message: "公告内容不超过100字符",
            trigger: "blur"
          }
        ],
        notice_end_date: [
          {
            required: true,
            message: "请设置结束时间",
            trigger: "change"
          }
        ]
      }
    }
  },
  mounted() {
    this.$request({
      url: "/getNotice",
      method: "get"
    }).then(res => {
      this.spinning = false
      this.formData = res.data
    })
  },
  methods: {
    disabledDate(current) {
      return current && current < this.$moment().endOf("day")
    },
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.isEnable = false
          this.$request({
            url: "/setNotice",
            method: "post",
            data: {
              notice_title: this.formData.notice_title,
              notice_content: this.formData.notice_content,
              notice_end_date: this.formData.notice_end_date,
              notice_show: this.formData.notice_show
            }
          })
            .then(res => {
              this.isEnable = true
              this.$message.success(res.message)
            })
            .catch(() => {
              this.isEnable = true
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 6px;
}

.page {
  min-width: 300px;
  width: 400px;
}

.page-submit {
  margin-top: 15px;
}
</style>
