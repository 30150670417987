<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-spin :spinning="loading">
      <a-form-model
        class="page-content"
        ref="iform"
        :model="formData"
        :rules="rules"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="用户名" prop="username">
          <a-input
            v-model="formData.username"
            type="text"
            placeholder="请输入用户名"
            size="large"
          >
            <a-icon type="user" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号码" prop="mobile">
          <a-input
            v-model="formData.mobile"
            type="number"
            placeholder="请输入手机号码"
            size="large"
          >
            <a-icon type="mobile" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="登录密码" prop="password">
          <a-input-password
            ref="ipassword"
            v-model="formData.password"
            type="password"
            placeholder="请输入登录密码"
            size="large"
          >
            <a-icon type="lock" slot="prefix" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            :disabled="!isEnable"
            :loading="!isEnable"
          >
            确认提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      isEnable: true,
      loading: true,
      formData: {
        id: undefined,
        username: undefined,
        mobile: undefined,
        password: undefined
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请输入有效的用户名",
            trigger: "blur"
          },
          {
            max: 30,
            message: "请输入有效的用户名",
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            pattern: /^1[23456789]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "密码最少6位",
            trigger: "blur"
          }
        ]
      }
    }
  },
  mounted() {
    if (this.$route.path == "/account/staff/edit") {
      if (this.$route.query.id) {
        this.$request({
          url: "/staffAccount",
          method: "get",
          params: {
            id: this.$route.query.id
          }
        }).then(res => {
          this.formData.id = res.data.id
          this.formData.username = res.data.username
          this.formData.mobile = res.data.mobile
          this.formData.password = "000000"
          this.$refs.ipassword.disabled = true
          this.loading = false
        })
      } else {
        this.$router.replace("/account/staff/list")
      }
    } else {
      this.loading = false
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.isEnable = false
          if (this.formData.id) {
            this.$request({
              url: "/staffAccount",
              method: "put",
              data: {
                id: this.formData.id,
                username: this.formData.username,
                mobile: this.formData.mobile
              }
            })
              .then(res => {
                this.$message.success(res.message)
                setTimeout(() => {
                  this.$router.replace("/account/staff/list")
                }, 2000)
              })
              .catch(() => {
                this.isEnable = true
              })
          } else {
            const password = this.$md5(this.formData.password)
            this.$request({
              url: "/staffAccount",
              method: "post",
              data: {
                username: this.formData.username,
                mobile: this.formData.mobile,
                password: password
              }
            })
              .then(res => {
                this.$message.success(res.message)
                setTimeout(() => {
                  this.$router.replace("/account/staff/list")
                }, 2000)
              })
              .catch(() => {
                this.isEnable = true
              })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-content {
  min-width: 260px;
  width: 350px;
  margin: 30px auto 0;
}

.ant-form-item button {
  width: 100%;
  margin-top: 30px;
}
</style>
