<template>
  <a-spin :spinning="spinning">
    <a-form-model
      ref="iform"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 15 }"
      :model="formData"
      :rules="rules"
      @submit="submit"
      @submit.native.prevent
    >
      <a-form-model-item label="线路产品" prop="product_id">
        <a-select
          v-model="formData.product_id"
          placeholder="请选择线路产品"
          @change="selectChange"
          :disabled="isEdit"
        >
          <a-select-option
            v-for="item in product_list"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}
            <a-tag v-if="item.set_out === 1" color="green">仅长岛出发</a-tag>
            <a-tag v-if="item.set_out === 2" color="pink">支持岛外出发</a-tag>
            <a-tag v-if="item.holiday === 1" color="red">区分节假日</a-tag>
            <a-tag v-if="item.days === 0" color="red">一日游</a-tag>
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="产品信息" v-if="product">
        <div class="page-product-info">
          <a-row :gutter="20">
            <a-col :span="8"
              >成团人数:
              <span class="warning">{{ product.min_people }}</span> 人</a-col
            >
            <a-col :span="8" v-if="product.days > 0"
              >住宿天数:
              <span class="warning">{{ product.days }}</span> 晚</a-col
            >
            <a-col :span="8" v-else
              >住宿天数: <span class="warning">不住宿</span></a-col
            >
            <a-col :span="8" v-if="product.change_time > 0"
              >退改政策: 提前
              <span class="warning">{{ product.change_time }}</span>
              天退改</a-col
            >
            <a-col :span="8" v-else
              >退改政策: <span class="warning">随时退改</span></a-col
            >
          </a-row>
          <div>产品简介: {{ product.content }}</div>
          <div v-if="product.remark" class="warning">
            产品备注: {{ product.remark }}
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="游玩日期"
        prop="tour_date"
        :extra="tourDateExtra"
      >
        <a-date-picker
          class="page-input-width"
          v-model="formData.tour_date"
          :disabled="!isEnable"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          placeholder="请选择游玩日期"
          :disabled-date="disabledDate"
        />
      </a-form-model-item>
      <a-form-model-item label="来源平台" prop="source">
        <a-select
          class="page-input-width"
          v-model="formData.source"
          placeholder="请选择来源平台"
          :disabled="!isEnable"
        >
          <template v-for="key in 8">
            <a-select-option
              :key="key"
              :value="key"
              v-if="ORDER_SOURCE_MAP[key].allshow || isStaff"
              >{{ ORDER_SOURCE_MAP[key].text }}</a-select-option
            >
          </template>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="联系人姓名" prop="contacts">
        <a-input
          class="page-input-width"
          v-model="formData.contacts"
          type="text"
          placeholder="请输入联系人姓名"
          :disabled="!isEnable"
        >
          <a-icon type="idcard" slot="prefix" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号码" prop="mobile">
        <a-input
          class="page-input-width"
          v-model="formData.mobile"
          type="number"
          placeholder="请输入联系人手机号码"
          :disabled="!isEnable"
        >
          <a-icon type="mobile" slot="prefix" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="备用号码" prop="mobile_spare">
        <a-input
          class="page-input-width"
          v-model="formData.mobile_spare"
          type="number"
          placeholder="请输入备用联系方式(可选)"
          :disabled="!isEnable"
        >
          <a-icon type="mobile" slot="prefix" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        label="出发地点"
        prop="set_out"
        v-if="product && product.set_out == 2"
      >
        <a-select
          class="page-input-width"
          v-model="formData.set_out"
          placeholder="请选择出发地点"
          @change="setOutChange"
          :disabled="!isEnable"
        >
          <a-select-option :value="1">长岛出发</a-select-option>
          <a-select-option :value="2">蓬莱出发</a-select-option>
          <a-select-option :value="3">烟台出发</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="对接司机号码"
        prop="mobile_docking"
        v-if="formData.set_out == 3"
        extra="请输入烟台到蓬莱的司机电话"
      >
        <a-input
          class="page-input-width"
          v-model="formData.mobile_docking"
          type="number"
          placeholder="请输入对接司机手机号码(可选)"
          :disabled="!isEnable"
        >
          <a-icon type="mobile" slot="prefix" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="订单备注" prop="remark">
        <a-textarea
          class="page-input-width"
          v-model="formData.remark"
          :auto-size="{ minRows: 3, maxRows: 6 }"
          placeholder="请输入订单备注(可选)"
          :disabled="!isEnable"
        />
      </a-form-model-item>
      <a-row>
        <a-col :span="15" :offset="5">
          <a-button
            type="primary"
            html-type="submit"
            size="large"
            :disabled="!isEnable"
            :loading="loading"
          >
            确认提交
          </a-button></a-col
        >
      </a-row>
    </a-form-model>
  </a-spin>
</template>

<script>
import * as config from "@/config"
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"

export default {
  props: {
    order_id: {
      type: String,
      default: undefined
    }
  },
  data() {
    const rules = {
      product_id: [
        {
          required: true,
          message: "请选择线路产品",
          trigger: "change"
        }
      ],
      tour_date: [
        {
          required: true,
          message: "请选择游玩日期",
          trigger: "change"
        }
      ],
      contacts: [
        {
          required: true,
          message: "请输入联系人姓名",
          trigger: "blur"
        },
        {
          min: 2,
          message: "请输入有效的联系人姓名",
          trigger: "blur"
        },
        {
          max: 30,
          message: "请输入有效的联系人姓名",
          trigger: "blur"
        }
      ],
      mobile: [
        {
          required: true,
          message: "请输入联系人的手机号码",
          trigger: "blur"
        },
        {
          pattern: /^1[23456789]\d{9}$/,
          message: "请输入有效的手机号码",
          trigger: "blur"
        }
      ],
      mobile_spare: [
        {
          pattern: /^1[23456789]\d{9}$/,
          message: "请输入有效的手机号码",
          trigger: "blur"
        }
      ],
      mobile_docking: [
        {
          pattern: /^1[23456789]\d{9}$/,
          message: "请输入有效的手机号码",
          trigger: "blur"
        }
      ],
      source: [
        {
          required: true,
          message: "请选择来源平台",
          trigger: "change"
        }
      ],
      remark: [
        {
          max: 300,
          message: "最多输入300字符",
          trigger: "change"
        }
      ],
      set_out: [
        {
          required: true,
          message: "请选择出发地点",
          trigger: "blur"
        }
      ]
    }

    return {
      ORDER_SOURCE_MAP: ORDER_SOURCE_MAP,
      rules: rules,
      spinning: true,
      isEnable: false,
      loading: false,
      product: undefined,
      product_list: [],
      formData: {
        product_id: undefined,
        tour_date: undefined,
        contacts: undefined,
        mobile: undefined,
        mobile_spare: undefined,
        mobile_docking: undefined,
        source: undefined,
        remark: undefined,
        set_out: 1
      }
    }
  },
  computed: {
    tourDateExtra() {
      if (this.product) {
        return (
          "产品有效时间: " +
          this.product.start_date +
          " ~ " +
          this.product.end_date
        )
      } else {
        return undefined
      }
    },
    isStaff() {
      return !(
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    },
    isEdit() {
      return this.$route.path === "/order/edit"
    }
  },
  mounted() {
    if (this.$route.path === "/order/edit") {
      //编辑订单
      if (this.$route.query.id) {
        this.$request({
          url: "/order",
          method: "get",
          params: {
            id: this.$route.query.id
          }
        }).then(res => {
          this.formData = res.data
          let product = res.data.product_info
          this.product_list.push(product)
          this.product = product
          this.isEnable = true
          this.spinning = false
        })
      } else {
        this.$router.replace("/order/list")
      }
    } else {
      //创建订单 请求产品列表
      this.$request({
        url: "/getProductInfo",
        method: "get"
      }).then(res => {
        //得到产品列表 如果产品列表为空则弹出对话框
        this.product_list = res.data
        if (this.product_list.length == 0) {
          this.$modal.info({
            title: "系统提示",
            content: "您的账号未设置有效的线路产品!",
            onOk: () => {
              this.$router.replace("/")
            }
          })
        } else {
          //如果存在订单ID则获取订单信息
          if (this.order_id) {
            this.$request({
              url: "/order",
              method: "get",
              params: {
                id: this.order_id
              }
            }).then(res => {
              this.formData = res.data
              const product = this.product_list.find(
                item => this.formData.product_id === item.id
              )
              if (product) {
                this.product = product
                this.isEnable = true
              } else {
                this.formData.product_id = undefined
                this.formData.tour_date = undefined
              }
              this.spinning = false
            })
          } else {
            this.spinning = false
          }
        }
      })
    }
  },
  methods: {
    setOutChange() {
      this.formData.mobile_docking = ""
    },
    selectChange(id) {
      this.isEnable = true
      this.formData.set_out = 1
      this.formData.tour_date = undefined
      const product = this.product_list.find(item => id === item.id)
      if (product) {
        this.product = product
        if (this.product.set_out != 1) {
          this.formData.mobile_docking = undefined
        }
      }
    },
    disabledDate(current) {
      if (this.product && current) {
        return (
          current < this.$moment(this.product.start_date) ||
          current > this.$moment(this.product.end_date).endOf("day") ||
          current < this.$moment().startOf("day")
        )
      }
      return true
    },
    submit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          let tour_date = this.$moment(this.formData.tour_date)
          let now = this.$moment().format("YYYY-MM-DD")
          if (tour_date.diff(now, "days") < 0) {
            return this.$message.error("无效的游玩时间")
          }
          this.isEnable = false
          this.loading = true
          let method = this.formData.id ? "put" : "post"
          this.$request({
            url: "/order",
            method: method,
            data: {
              id: this.order_id,
              product_id: this.formData.product_id,
              tour_date: this.formData.tour_date,
              contacts: this.formData.contacts,
              mobile: this.formData.mobile,
              mobile_spare: this.formData.mobile_spare,
              mobile_docking: this.formData.mobile_docking,
              source: this.formData.source,
              remark: this.formData.remark,
              set_out: this.formData.set_out
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$emit("next", res.data.order_id)
              }, 2000)
            })
            .catch(() => {
              this.isEnable = true
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.page-product-info {
  background: whitesmoke;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 25px;
  color: #666666;
}

.page-input-width {
  width: 50%;
  min-width: 350px;
}

.warning {
  color: rgb(245, 78, 0);
  font-weight: bold;
}

.ant-btn {
  margin: 30px 0;
  width: 30%;
  min-width: 200px;
}
</style>
