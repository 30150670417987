<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-spin :spinning="loading">
      <a-form-model
        class="page-content"
        ref="iform"
        :model="formData"
        :rules="rules"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="管家姓名" prop="name">
          <a-input
            v-model="formData.name"
            type="text"
            placeholder="请输入管家姓名"
            size="large"
          >
            <a-icon type="idcard" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号码" prop="mobile">
          <a-input
            v-model="formData.mobile"
            type="number"
            placeholder="请输入管家手机号码"
            size="large"
          >
            <a-icon type="mobile" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="车辆型号" prop="car_model">
          <a-input
            v-model="formData.car_model"
            type="text"
            placeholder="请输入车辆型号"
            size="large"
          >
            <a-icon type="car" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            :disabled="!isEnable"
            :loading="!isEnable"
          >
            确认提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      isEnable: true,
      loading: true,
      formData: {
        id: undefined,
        name: undefined,
        mobile: undefined,
        car_model: undefined
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入管家姓名",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请输入有效的姓名",
            trigger: "blur"
          },
          {
            max: 30,
            message: "请输入有效的姓名",
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入管家的手机号码",
            trigger: "blur"
          },
          {
            pattern: /^1[23456789]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur"
          }
        ],
        car_model: [
          {
            max: 30,
            message: "您输入的太多了...",
            trigger: "change"
          }
        ]
      }
    }
  },
  mounted() {
    if (this.$route.path == "/butler/edit") {
      if (this.$route.query.id) {
        this.formData.id = this.$route.query.id
        this.$request({
          url: "/butler",
          method: "get",
          params: {
            id: this.formData.id
          }
        }).then(res => {
          this.formData = res.data
          this.loading = false
        })
      } else {
        this.$router.replace("/butler/list")
      }
    } else {
      this.loading = false
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.isEnable = false
          let method = this.formData.id ? "put" : "post"
          this.$request({
            url: "/butler",
            method: method,
            data: {
              id: this.formData.id,
              name: this.formData.name,
              mobile: this.formData.mobile,
              car_model: this.formData.car_model
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.replace("/butler/list")
              }, 2000)
            })
            .catch(() => {
              this.isEnable = true
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.ant-form-item {
  margin-bottom: 10px;
}

.page-content {
  min-width: 260px;
  width: 350px;
  margin: 30px auto 0;
}

.ant-form-item button {
  width: 100%;
  margin-top: 30px;
}
</style>
