<template>
  <a-spin :spinning="spinning">
    <a-descriptions size="middle" bordered>
      <a-descriptions-item label="线路产品" :span="3">
        {{ productInfo.name }}
        <a-tag v-if="productInfo.set_out === 1" color="green">仅长岛出发</a-tag>
        <a-tag v-if="productInfo.set_out === 2" color="pink"
          >支持岛外出发</a-tag
        >
        <a-tag v-if="productInfo.holiday === 1" color="red">区分节假日</a-tag>
        <a-tag v-if="productInfo.days === 0" color="red">一日游</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="产品详情" :span="3">
        <span>{{ productInfo.content }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="产品备注" :span="3" v-if="productInfo.remark">
        <span class="warning">{{ productInfo.remark }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="订单编号 ">
        {{ order_id }}
      </a-descriptions-item>
      <a-descriptions-item label="联系人">
        {{ orderInfo.contacts }}
      </a-descriptions-item>
      <a-descriptions-item label="手机号码">
        {{ orderInfo.mobile }}
      </a-descriptions-item>
      <a-descriptions-item label="游玩日期">
        <span class="warning"
          >{{ orderInfo.tour_date }}
          <a-tag
            v-if="orderInfo.holiday_name && productInfo.holiday === 1"
            color="red"
          >
            {{ orderInfo.holiday_name }}</a-tag
          ></span
        >
      </a-descriptions-item>
      <a-descriptions-item label="出发地点">
        <a-tag v-if="orderInfo.set_out === 1" color="green">长岛</a-tag>
        <a-tag v-if="orderInfo.set_out === 2" color="pink">蓬莱</a-tag>
        <a-tag v-if="orderInfo.set_out === 3" color="red">烟台</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="订单金额">
        <span class="page-table-price">¥ {{ totalPrice }}</span>
      </a-descriptions-item>
    </a-descriptions>
    <!--table-->
    <a-table
      rowKey="key"
      class="page-table"
      :columns="columns"
      :dataSource="visitorList"
      :pagination="false"
      bordered
    >
      <a-select
        class="page-table-input page-table-select"
        placeholder="游客类型"
        size="small"
        slot="price_id"
        slot-scope="text, record"
        :disabled="!record.editable"
        :defaultValue="record.price_id"
        @change="value => handleChange(value, record.key, 'price_id')"
      >
        <a-select-option
          v-for="item in productPrice"
          :key="item.id"
          :value="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <template slot="price" slot-scope="text">
        <span v-if="text" class="page-table-price">¥ {{ text }}</span>
        <span v-else> N/A </span>
      </template>
      <template slot="name" slot-scope="text, record">
        <a-input
          v-if="record.editable"
          key="name"
          class="page-table-input"
          size="small"
          :value="text"
          placeholder="姓名"
          @change="e => handleChange(e.target.value, record.key, 'name')"
        />
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template slot="age" slot-scope="scope, record">
        <template v-if="!record.editable && record.id_card">
          <a-tag
            v-if="idToAge(record.id_card, orderInfo.tour_date) < 18"
            color="red"
            >{{ idToAge(record.id_card, orderInfo.tour_date) }}</a-tag
          >
          <a-tag
            v-else-if="idToAge(record.id_card, orderInfo.tour_date) >= 60"
            color="red"
            >{{ idToAge(record.id_card, orderInfo.tour_date) }}</a-tag
          >
          <a-tag v-else color="green">{{
            idToAge(record.id_card, orderInfo.tour_date)
          }}</a-tag>
        </template>
        <template v-else>
          N/A
        </template>
      </template>
      <template slot="id_card" slot-scope="text, record">
        <a-input
          v-if="record.editable"
          key="id_card"
          class="page-table-input"
          size="small"
          :value="text"
          placeholder="身份证号码"
          @change="e => handleChange(e.target.value, record.key, 'id_card')"
        />
        <template v-else>{{ text }}</template>
      </template>
      <template slot="action" slot-scope="text, record">
        <template v-if="record.editable">
          <span v-if="record.isNew">
            <a @click="save(record)">添加</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此游客？"
              @confirm="remove(record.key)"
            >
              <a>删除</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a @click="save(record)">保存</a>
            <a-divider type="vertical" />
            <a @click="cancel(record.key)">取消</a>
          </span>
        </template>
        <span v-else>
          <a @click="toggle(record.key)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="是否要删除此行？" @confirm="remove(record.key)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </template>
    </a-table>
    <!--form-->
    <a-form-model
      ref="iform"
      :model="formData"
      :rules="rules"
      @submit="handleSubmit"
      @submit.native.prevent
    >
      <a-row :gutter="30">
        <a-col :span="6">
          <a-form-model-item
            label="幼儿人数(身高1.2m以下)"
            extra="必须录入准确的幼儿人数!"
            prop="child_number"
          >
            <a-input-number
              class="page-input-number"
              v-model="formData.child_number"
              :min="0"
              placeholder="请输入幼儿人数"
              @change="childChange"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="6" v-if="productInfo.type === 1">
          <a-form-model-item label="支付状态" prop="pay_state">
            <a-select
              v-model="formData.pay_state"
              placeholder="请选择支付状态"
              @change="payChange"
            >
              <a-select-option :value="0">未支付</a-select-option>
              <a-select-option :value="1">已支付定金</a-select-option>
              <a-select-option :value="3">已支付全款</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item
            v-if="productInfo.type === 1"
            label="最终订单金额"
            prop="total_price"
          >
            <a-input-number
              class="page-input-number"
              v-model="formData.total_price"
              :min="0"
              :step="0.01"
              placeholder="请输入实付金额"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="6" v-if="formData.pay_state === 1">
          <a-form-model-item label="定金金额" prop="deposit">
            <a-input-number
              class="page-input-number"
              v-model="formData.deposit"
              :min="0"
              placeholder="请输入定金金额"
            />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-form-model-item>
        <a-button
          class="page-button-add"
          type="dashed"
          icon="plus"
          @click="addVisitor"
        >
          新增游客
        </a-button>
        <a-button
          class="page-button-complete"
          type="primary"
          html-type="submit"
          :disabled="!isEnable"
          :loading="loading"
        >
          确认提交
        </a-button>
        <a-button class="page-button-back" @click="goBack">
          修改订单信息
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>

<script>
import { idToAge } from "@/utils/utils"
import * as types from "@/store/vuex-types"

export default {
  props: {
    order_id: {
      type: String,
      default: undefined
    }
  },
  data() {
    const columns = [
      {
        title: "类型",
        dataIndex: "price_id",
        scopedSlots: { customRender: "price_id" },
        width: "20%"
      },
      {
        title: "价格",
        dataIndex: "price",
        width: "15%",
        scopedSlots: { customRender: "price" }
      },
      {
        title: "年龄",
        dataIndex: "age",
        scopedSlots: { customRender: "age" }
      },
      {
        title: "姓名",
        dataIndex: "name",
        width: "15%",
        scopedSlots: { customRender: "name" }
      },
      {
        title: "身份证号码",
        dataIndex: "id_card",
        scopedSlots: { customRender: "id_card" }
      },
      {
        title: "操作",
        key: "action",
        width: "15%",
        scopedSlots: { customRender: "action" }
      }
    ]

    const rules = {
      child_number: [
        {
          required: true,
          message: "请输入幼儿人数",
          trigger: "blur"
        },
        {
          pattern: /^\d+$/,
          message: "请输入有效的人数",
          trigger: "blur"
        }
      ],
      pay_state: [
        {
          required: true,
          message: "请选择支付状态",
          trigger: "blur"
        }
      ],
      deposit: [
        {
          required: true,
          message: "请输入定金金额",
          trigger: "blur"
        },
        {
          pattern: /^\d+$/,
          message: "定金必须为整数金额",
          trigger: "blur"
        }
      ],
      total_price: [
        {
          required: true,
          message: "请输入实付金额",
          trigger: "blur"
        }
      ]
    }

    return {
      columns: columns,
      rules: rules,
      spinning: true,
      loading: false,
      totalPrice: 0,
      visitorNumber: 0,
      visitorList: [],
      orderInfo: {
        contacts: undefined,
        mobile: undefined,
        tour_date: undefined,
        holiday_name: undefined,
        travel_agency_name: undefined,
        set_out: undefined
      },
      productPrice: undefined,
      productInfo: {
        type: undefined,
        name: undefined,
        set_out: undefined,
        holiday: undefined,
        min_people: undefined,
        days: undefined,
        content: undefined,
        remark: undefined
      },
      formData: {
        child_number: 0,
        pay_state: 0,
        deposit: 0,
        total_price: 0
      }
    }
  },
  mounted() {
    this.$request({
      url: "/getOrderInfo",
      method: "get",
      params: {
        id: this.order_id
      }
    }).then(res => {
      this.orderInfo = res.data.order_info
      this.productInfo = res.data.product_info
      this.productPrice = res.data.product_price
      if (this.orderInfo.child_number != undefined) {
        this.formData.child_number = this.orderInfo.child_number
      }
      if (this.orderInfo.pay_state != undefined) {
        this.formData.pay_state = this.orderInfo.pay_state
      }
      if (this.orderInfo.deposit != undefined) {
        this.formData.deposit = this.orderInfo.deposit
      }
      //恢复游客信息
      let visitor_list = []
      if (res.data.visitor_list.length > 0) {
        visitor_list = res.data.visitor_list
      } else {
        if (this.$store.getters.getVisitors) {
          visitor_list = this.$store.getters.getVisitors
          this.$store.commit(types.CLEAR_VISITORS)
        }
      }
      if (visitor_list.length > 0) {
        for (let i = 0; i < visitor_list.length; i++) {
          let price_id = undefined
          let price_name = undefined
          let price = undefined
          let productPrice = this.productPrice.find(
            item => visitor_list[i].price_id == item.id
          )
          if (!productPrice) {
            productPrice = this.productPrice.find(
              item => visitor_list[i].price_name == item.name
            )
          }
          if (productPrice) {
            price_id = productPrice.id
            price_name = productPrice.name
            price = productPrice.price
          }
          this.visitorList.push({
            key: i.toString(),
            isNew: false,
            editable: true,
            price_id: price_id,
            price_name: price_name,
            price: price,
            name: visitor_list[i].name,
            id_card: visitor_list[i].id_card
          })
        }
        this.calculationTotalPrice()
      }
      if (this.orderInfo.total_price != undefined) {
        this.formData.total_price = this.orderInfo.total_price
      }
      if (this.productPrice.length == 0) {
        this.$modal.info({
          title: "系统提示",
          content: "该产品缺少有效的价格分类,请选择其他产品!",
          onOk: () => {
            this.goBack()
          }
        })
      }
      this.spinning = false
    })
  },
  computed: {
    isEnable() {
      return (
        this.productInfo.min_people &&
        this.visitorNumber >= this.productInfo.min_people
      )
    }
  },
  methods: {
    idToAge,
    childChange() {
      this.calculationTotalPrice()
    },
    payChange() {
      this.formData.deposit = 0
      this.formData.total_price = this.totalPrice
    },
    goBack() {
      if (this.visitorList.length > 0) {
        this.$store.commit(types.SET_VISITORS, this.visitorList)
      }
      this.$emit("goBack")
    },
    addVisitor() {
      const length = this.visitorList.length
      this.visitorList.push({
        key:
          length === 0
            ? "0"
            : (parseInt(this.visitorList[length - 1].key) + 1).toString(),
        editable: true,
        isNew: true,
        price_id: undefined,
        price_name: undefined,
        price: undefined,
        name: "",
        id_card: ""
      })
    },
    //处理数据修改
    handleChange(value, key, column) {
      const newData = [...this.visitorList]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        if (column === "price_id") {
          const item = this.productPrice.find(item => value == item.id)
          if (item) {
            target.price = item.price
            target.price_name = item.name
          }
        }
        this.visitorList = newData
      }
    },
    //保存数据
    save(record) {
      if (
        record.price_id === undefined ||
        record.price_name === undefined ||
        record.price === "" ||
        record.name === "" ||
        record.id_card === ""
      ) {
        this.$message.error("请填写完整的游客信息")
        return
      }
      if (!this.testIdCard(record.id_card)) {
        this.$message.error("请输入有效的身份证号码")
        return
      }
      const target = this.visitorList.find(item => item.key === record.key)
      target.editable = false
      target.isNew = false
      this.calculationTotalPrice()
    },
    testIdCard(str) {
      var reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      return reg.test(str)
    },
    //删除数据
    remove(key) {
      const newData = this.visitorList.filter(item => item.key !== key)
      this.visitorList = newData
      this.calculationTotalPrice()
    },
    //取消编辑
    cancel(key) {
      const target = this.visitorList.find(item => item.key === key)
      Object.keys(target).forEach(key => {
        target[key] = target._originalData[key]
      })
      target._originalData = undefined
    },
    //编辑
    toggle(key) {
      const target = this.visitorList.find(item => item.key === key)
      target._originalData = { ...target }
      target.editable = !target.editable
    },
    //计算总价
    calculationTotalPrice() {
      let totalPrice = 0
      let visitorNumber = 0
      this.visitorList.forEach(visitor => {
        if (!visitor.editable) {
          totalPrice += parseFloat(visitor.price)
          visitorNumber += 1
        }
      })
      this.visitorNumber = visitorNumber
      this.totalPrice = totalPrice
      this.formData.total_price = totalPrice
    },
    //提交数据
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          for (let i = 0; i < this.visitorList.length; i++) {
            if (this.visitorList[i].editable === true) {
              this.$message.error("你有未保存的游客!")
              return
            }
          }
          if (this.formData.pay_state === 1) {
            if (this.formData.deposit < 100) {
              this.$message.error("定金不能少于100元!")
              return
            }
          }
          this.$modal.confirm({
            title: "系统提示",
            content:
              "请确认录入信息准确,订单分配后将无法修改订单信息!(如需修改请联系管理员)",
            okText: "提交订单",
            onOk: () => {
              this.loading = true
              this.$request({
                url: "/confirmOrder",
                method: "post",
                data: {
                  id: this.order_id,
                  child_number: this.formData.child_number,
                  pay_state: this.formData.pay_state,
                  deposit: this.formData.deposit,
                  total_price: this.formData.total_price,
                  visitor_list: this.visitorList
                }
              })
                .then(res => {
                  this.loading = false
                  this.$message.success(res.message)
                  setTimeout(() => {
                    this.$emit("complete")
                  }, 2000)
                })
                .catch(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 30px;
}

.page-table {
  margin: 30px 0;
}

.page-table-select {
  width: 100%;
}

.page-table-price {
  font-weight: bold;
  color: red;
}

.page-table-input {
  margin: -5px 0;
}

.page-input-number {
  width: 100%;
}

.page-button-add {
  width: 20%;
  min-width: 200px;
}

.page-button-back {
  float: right;
  margin-right: 30px;
}

.page-button-complete {
  float: right;
  width: 20%;
  min-width: 200px;
}

.warning {
  color: rgb(245, 78, 0);
}
</style>
