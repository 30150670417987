<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-select
      v-if="isStaff"
      slot="extra"
      class="page-select"
      v-model="type"
      placeholder="订单类型"
      @change="selectChange"
    >
      <a-select-option :value="1">自营订单</a-select-option>
      <a-select-option :value="2">OTA订单</a-select-option>
      <a-select-option :value="3">分销订单</a-select-option>
    </a-select>

    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <span slot="tour_date" slot-scope="scope">
        {{ scope }}
      </span>
      <span slot="state" slot-scope="scope">
        <a-badge
          :color="scope | orderStateCode"
          :text="scope | orderStateText"
        />
      </span>
      <span slot="type" slot-scope="scope">
        <a-tag v-if="scope === 1" color="green">自营</a-tag>
        <a-tag v-if="scope === 2" color="blue">OTA</a-tag>
        <a-tag v-if="scope === 3" color="orange">分销</a-tag>
      </span>
      <span slot="set_out" slot-scope="scope, record">
        <a-tag v-if="record.set_out === 1" color="green">长岛</a-tag>
        <a-tag v-if="record.set_out === 2" color="pink">蓬莱</a-tag>
        <a-tag v-if="record.set_out === 3" color="red">烟台</a-tag>
      </span>
      <span slot="product" slot-scope="scope, record">
        <a-tag v-if="record.type === 1" color="green">自营</a-tag>
        <a-tag v-if="record.type === 2" color="blue">OTA</a-tag>
        <a-tag v-if="record.type === 3" color="orange">分销</a-tag>
        {{ record.product_name }}
        <a-tag v-if="record.product_holiday === 1" color="red"
          >区分节假日</a-tag
        >
        <a-tag v-if="record.product_days === 0" color="red">一日游</a-tag>
      </span>
      <a-tooltip slot="contacts" slot-scope="scope, record">
        <template slot="title"> TEL: {{ record.mobile }} </template>
        {{ scope }}
      </a-tooltip>
      <span slot="action" slot-scope="scope">
        <router-link :to="{ path: 'edit', query: { id: scope.id } }"
          >完善订单</router-link
        ><a-divider type="vertical" />
        <a-popconfirm
          placement="bottomRight"
          title="您确定要取消该订单吗?"
          @confirm="cancel(scope.id)"
        >
          <a>取消订单</a>
        </a-popconfirm>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import * as config from "@/config"
import { ORDER_STATE_MAP } from "@/utils/order-state-utils"
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px",
        sorter: (a, b) => b.id - a.id
      },
      {
        title: "时间",
        dataIndex: "tour_date",
        scopedSlots: { customRender: "tour_date" },
        sorter: (a, b) => {
          return (
            this.$moment(b.tour_date).unix() - this.$moment(a.tour_date).unix()
          )
        }
      },
      {
        title: "状态",
        dataIndex: "state",
        scopedSlots: { customRender: "state" }
      },
      {
        title: "出发",
        dataIndex: "set_out",
        scopedSlots: { customRender: "set_out" }
      },
      {
        title: "产品",
        dataIndex: "product",
        scopedSlots: { customRender: "product" }
      },
      {
        title: "联系人",
        dataIndex: "contacts",
        scopedSlots: { customRender: "contacts" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      columns: columns,
      loading: false,
      data: [],
      pagination: {},
      type: 1
    }
  },
  filters: {
    orderStateCode(state) {
      return ORDER_STATE_MAP[state].state
    },
    orderStateText(state) {
      return ORDER_STATE_MAP[state].text
    },
    orderSourceText(code) {
      return ORDER_SOURCE_MAP[code].text
    }
  },
  computed: {
    isStaff() {
      return !(
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    }
  },
  mounted() {
    if (this.$store.getters.getState.permission === config.PERMISSION_COMMON) {
      this.type = 2
    }
  },
  activated() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  methods: {
    selectChange() {
      const pager = { ...this.pagination }
      pager.current = 1
      this.pagination = pager
      this.getData({
        page: this.pagination.current,
        size: this.pagination.pageSize
      })
    },
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = config.PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getOrderList",
        method: "get",
        params: {
          page: page,
          size: size,
          type: this.type,
          state: 1
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
      })
    },
    cancel(id) {
      this.$request({
        url: "/order",
        method: "delete",
        data: {
          id: id
        }
      }).then(res => {
        this.getData({
          page: 1,
          size: this.pagination.pageSize
        })
        this.$message.success(res.message)
      })
    }
  }
}
</script>
<style>
.ant-card {
  min-height: 100%;
}

.page-select {
  width: 180px;
  margin-left: 30px;
}
</style>
