<template>
  <a-card
    :title="$route.meta.title + ' ID:' + $route.query.id"
    :bordered="false"
    hoverable
  >
    <a slot="extra" @click="$router.go(-1)">返回</a>
    <a-spin :spinning="spinning">
      <div class="page">
        <OrderInfoView :orderData="orderData" />
        <a-row :gutter="10">
          <a-col :span="12"
            ><div class="page-logs">
              <span>订单操作日志：</span>
              <a-timeline>
                <a-timeline-item
                  v-for="(log, index) in logs"
                  :key="index"
                  color="green"
                >
                  <p>{{ log.info }}</p>
                  <p class="page-logs-exp">
                    操作用户:{{ log.username }} 时间:{{ log.time }}
                  </p>
                </a-timeline-item>
              </a-timeline>
            </div>
          </a-col>
          <a-col :span="12" v-if="isStaff && orderData.order_info.state > 3">
            <div class="page-logs">
              <h3>费用明细:</h3>
              <p>费用合计:{{ orderData.order_info.total_price }}</p>
              <p>酒店费用:{{ orderData.order_info.cost_hotel }}</p>
              <p>交通费用:{{ orderData.order_info.cost_car }}</p>
              <p>门票费用:{{ orderData.order_info.cost_ticket }}</p>
              <p>海上项目:{{ orderData.order_info.cost_sea }}</p>
              <p>岛外交通:{{ orderData.order_info.cost_ship }}</p>
              <p>分销利润:{{ orderData.order_info.cost_profit }}</p>
              <p>其他费用:{{ orderData.order_info.cost_other }}</p>
              <p>最终利润:{{ orderData.order_info.profit }}</p>
            </div></a-col
          >
        </a-row>
      </div>
    </a-spin>
  </a-card>
</template>

<script>
import * as config from "@/config"
import OrderInfoView from "@/components/OrderInfoView.vue"

export default {
  components: { OrderInfoView },
  data() {
    return {
      spinning: true,
      orderData: {},
      logs: []
    }
  },
  computed: {
    isStaff() {
      return !(
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    }
  },
  mounted() {
    if (this.$route.query.id) {
      let request1 = this.$request({
        url: "/getOrderInfo",
        method: "get",
        params: {
          id: this.$route.query.id
        }
      })
      let request2 = this.$request({
        url: "/getOrderLogs",
        method: "get",
        params: {
          id: this.$route.query.id
        }
      })
      Promise.all([request1, request2]).then(res => {
        this.orderData = res[0].data
        this.logs = res[1].data
        this.spinning = false
      })
    } else {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.page {
  padding: 20px;
}

.page-logs {
  padding: 20px;
  background: whitesmoke;
}

.page-logs span {
  font-size: 15px;
}

.page-logs ul {
  margin-top: 20px;
}

.page-logs-exp {
  font-size: 12px;
}
</style>
