<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <router-link slot="extra" :to="{ path: 'record' }">新增记录</router-link>
    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <span slot="username" slot-scope="scope">
        <a-tag color="red">{{ scope }}</a-tag>
      </span>
      <span slot="source" slot-scope="scope">
        <a-tag color="blue">{{ scope | orderSourceText }}</a-tag>
      </span>
      <span slot="days" slot-scope="scope">
        <a-tag color="red">{{ scope }} 天</a-tag>
      </span>
      <span slot="people_number" slot-scope="scope">
        <a-tag color="red">{{ scope }} 人</a-tag>
      </span>
      <span slot="action" slot-scope="scope">
        <router-link :to="{ path: 'record', query: { id: scope.id } }"
          >编辑</router-link
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="bottomRight"
          title="您确定要删除该记录吗?"
          @confirm="remove(scope.id)"
        >
          <a :disabled="!isAdmin">删除</a>
        </a-popconfirm>
      </span>
      <span
        slot="expandedRowRender"
        slot-scope="record"
        class="page-table-info"
      >
        订单备注: {{ record.remark }}
      </span>
    </a-table>
  </a-card>
</template>

<script>
import * as config from "@/config"
import { ORDER_SOURCE_MAP } from "@/utils/option-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px",
        sorter: (a, b) => b.id - a.id
      },
      {
        title: "时间",
        dataIndex: "tour_date",
        sorter: (a, b) => {
          return (
            this.$moment(b.tour_date).unix() - this.$moment(a.tour_date).unix()
          )
        }
      },
      {
        title: " 用户",
        dataIndex: "username",
        scopedSlots: { customRender: "username" }
      },
      {
        title: "来源",
        dataIndex: "source",
        scopedSlots: { customRender: "source" }
      },
      {
        title: "酒店",
        dataIndex: "hotel_name"
      },
      {
        title: "天数",
        dataIndex: "days",
        scopedSlots: { customRender: "days" }
      },
      {
        title: "人数",
        dataIndex: "people_number",
        scopedSlots: { customRender: "people_number" }
      },
      {
        title: "联系人",
        dataIndex: "contacts"
      },
      {
        title: "电话",
        dataIndex: "mobile"
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      columns: columns,
      loading: false,
      data: [],
      pagination: {}
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getState.permission === config.PERMISSION_ADMIN
    }
  },
  filters: {
    orderSourceText(code) {
      return ORDER_SOURCE_MAP[code].text
    }
  },
  activated() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  methods: {
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = config.PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getOrderList",
        method: "get",
        params: {
          page: page,
          size: size,
          state: 5,
          type: 0
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
      })
    },
    remove(id) {
      this.$request({
        url: "/record",
        method: "delete",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data.splice(index, 1)
        this.$message.success(res.message)
      })
    }
  }
}
</script>
<style>
.ant-card {
  min-height: 100%;
}

.red {
  color: red;
}

.page-select {
  width: 180px;
  margin-left: 30px;
}

.page-table-info {
  font-size: 12px;
}
</style>
