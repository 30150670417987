<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <span slot="extra">
      <span class="page-head-text"
        >入账金额:
        <span
          :class="
            amount > 0 ? 'page-head-amount red' : 'page-head-amount green'
          "
          >¥ {{ amount }}
        </span></span
      >
      <a-button type="primary" :loading="loading" @click="handleSubmit">
        确认提交
      </a-button></span
    >
    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :row-selection="{
        selectedRowKeys: selected,
        onChange: onSelectChange
      }"
    >
      <a-tooltip slot="id" slot-scope="scope, record">
        <template slot="title">
          查看订单详情
        </template>
        <router-link :to="{ path: '/order/info', query: { id: record.id } }">{{
          scope
        }}</router-link>
      </a-tooltip>
      <span slot="tour_date" slot-scope="scope">
        {{ scope }}
      </span>
      <span slot="product" slot-scope="scope, record">
        <a-tag v-if="record.type === 1" color="green">自营</a-tag>
        <a-tag v-if="record.type === 2" color="blue">OTA</a-tag>
        <a-tag v-if="record.type === 3" color="orange">分销</a-tag>
        {{ record.product_name }}
        <a-tag v-if="record.product_set_out === 1" color="green"
          >长岛出发</a-tag
        >
        <a-tag v-if="record.product_set_out === 2" color="pink">蓬莱出发</a-tag>
        <a-tag v-if="record.product_set_out === 3" color="red">烟台出发</a-tag>
        <a-tag v-if="record.product_holiday === 1" color="red"
          >区分节假日</a-tag
        >
        <a-tag v-if="record.product_days === 0" color="red">一日游</a-tag>
      </span>
      <a-tooltip slot="contacts" slot-scope="scope, record">
        <template slot="title"> TEL: {{ record.mobile }} </template>
        {{ scope }}
      </a-tooltip>
      <div slot="people_number" slot-scope="scope, record">
        {{ record.people_number }}人
        <span v-if="record.child_number > 0">
          +{{ record.child_number }}幼儿
        </span>
      </div>
      <span slot="total_price" slot-scope="scope" class="red"
        >¥ {{ scope }}</span
      >
      <span
        slot="profit"
        slot-scope="scope"
        :class="scope > 0 ? 'red' : 'green'"
        >¥ {{ scope }}</span
      >
    </a-table>
  </a-card>
</template>

<script>
import { ORDER_STATE_MAP } from "@/utils/order-state-utils"
import { PAY_STATE_MAP } from "@/utils/pay-state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px",
        scopedSlots: { customRender: "id" },
        sorter: (a, b) => b.id - a.id
      },
      {
        title: "时间",
        dataIndex: "tour_date",
        scopedSlots: { customRender: "tour_date" },
        sorter: (a, b) => {
          return (
            this.$moment(b.tour_date).unix() - this.$moment(a.tour_date).unix()
          )
        }
      },
      {
        title: "产品",
        dataIndex: "product",
        scopedSlots: { customRender: "product" }
      },
      {
        title: "联系人",
        dataIndex: "contacts",
        scopedSlots: { customRender: "contacts" }
      },
      {
        title: "人数",
        dataIndex: "people_number",
        scopedSlots: { customRender: "people_number" }
      },
      {
        title: "总价",
        dataIndex: "total_price",
        scopedSlots: { customRender: "total_price" }
      },
      {
        title: "利润",
        dataIndex: "profit",
        scopedSlots: { customRender: "profit" }
      }
    ]
    return {
      columns: columns,
      loading: true,
      data: [],
      selected: [],
      amount: 0
    }
  },
  filters: {
    orderStateCode(state) {
      return ORDER_STATE_MAP[state].state
    },
    orderStateText(state) {
      return ORDER_STATE_MAP[state].text
    },
    payStateCode(state) {
      return PAY_STATE_MAP[state].state
    },
    payStateText(state) {
      return PAY_STATE_MAP[state].text
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selected = selectedRowKeys
      let arr = this.data.filter(item => this.selected.includes(item.id))
      let amount = 0
      arr.forEach(item => {
        amount += Number(item.profit)
      })
      this.amount = amount
    },
    getData() {
      this.$request({
        url: "/getEntryOrder",
        method: "get"
      }).then(res => {
        this.data = res.data
        this.loading = false
      })
    },
    handleSubmit() {
      if (this.selected.length === 0) {
        this.$message.error("未选择订单")
        return
      }
      this.$modal.confirm({
        title: "系统提示",
        content: "您确定要将利润入账吗?",
        onOk: () => {
          this.loading = true
          this.$request({
            url: "/profitEntry",
            method: "post",
            data: {
              orders: this.selected
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.replace("/account-book")
              }, 2000)
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style>
.ant-card {
  min-height: 100%;
}

.ant-row {
  margin-bottom: 5px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.page-head-text {
  margin-right: 60px;
  font-size: 12px;
}

.page-head-amount {
  font-weight: 700;
  margin-left: 5px;
}
</style>
