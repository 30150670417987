<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <a-spin :spinning="loading">
      <a-form-model
        class="page"
        ref="iform"
        :model="formData"
        :rules="rules"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-row :gutter="20">
          <a-col :span="12"
            ><a-form-model-item label="产品名称" prop="name">
              <a-input
                v-model="formData.name"
                type="text"
                placeholder="请输入产品名称"
              /> </a-form-model-item
          ></a-col>
          <a-col :span="4"
            ><a-form-model-item label="价格模式" prop="holiday">
              <a-select
                v-model="formData.holiday"
                placeholder="请选择价格模式"
                @change="holidayChange"
                :disabled="isEdit"
              >
                <a-select-option :value="0">不区分节假日</a-select-option>
                <a-select-option :value="1">区分节假日</a-select-option>
              </a-select>
            </a-form-model-item></a-col
          >
          <a-col :span="4"
            ><a-form-model-item label="产品类型" prop="type">
              <a-select
                v-model="formData.type"
                placeholder="请选择产品类型"
                @change="typeChange"
                :disabled="isEdit"
              >
                <a-select-option :value="1">自营产品</a-select-option>
                <a-select-option :value="2">OTA产品</a-select-option>
                <a-select-option :value="3">分销产品</a-select-option>
              </a-select>
            </a-form-model-item></a-col
          >
          <a-col :span="4"
            ><a-form-model-item label="出发地点" prop="set_out">
              <a-select
                v-model="formData.set_out"
                placeholder="请选择出发地点"
                @change="setOutChange"
              >
                <a-select-option :value="1">仅长岛出发</a-select-option>
                <a-select-option :value="2">支持岛外出发</a-select-option>
              </a-select>
            </a-form-model-item></a-col
          >
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8"
            ><a-form-model-item label="产品有效时间" prop="date">
              <a-range-picker
                v-model="formData.date"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
              /> </a-form-model-item
          ></a-col>
          <a-col :span="4"
            ><a-form-model-item label="最少成团人数" prop="min_people">
              <a-input-number
                class="page-input-number"
                v-model="formData.min_people"
                :min="1"
                placeholder="请输入最少成团人数"
                :disabled="isEdit"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="4"
            ><a-form-model-item label="住宿天数" prop="days">
              <a-input-number
                class="page-input-number"
                v-model="formData.days"
                :min="0"
                :max="10"
                placeholder="一日游不住宿填0"
                @change="daysChange"
                :disabled="isEdit"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="4"
            ><a-form-model-item label="最晚退改天数" prop="change_time">
              <a-input-number
                class="page-input-number"
                v-model="formData.change_time"
                :min="0"
                :max="7"
                placeholder="如随时退改填0"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item label="交通费用基数" prop="car_cost">
              <a-input-number
                class="page-input-number"
                v-model="formData.car_cost"
                :min="0"
                placeholder="人均交通费用"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12"
            ><a-form-model-item label="产品详情" prop="content">
              <a-textarea
                v-model="formData.content"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                placeholder="请输入产品详情"
              /> </a-form-model-item
          ></a-col>
          <a-col :span="12"
            ><a-form-model-item label="产品备注" prop="remark">
              <a-textarea
                v-model="formData.remark"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                placeholder="请输入产品备注"
              /> </a-form-model-item
          ></a-col>
        </a-row>
        <!-- Table -->
        <a-form-model-item>
          <a-table
            class="page-table"
            :columns="columns"
            :dataSource="priceList"
            :pagination="false"
            bordered
          >
            <template slot="name" slot-scope="text, record">
              <a-input
                v-if="record.editable"
                key="name"
                class="page-form-input"
                size="small"
                :value="text"
                placeholder="价格分类"
                :addonAfter="record.holiday === 1 ? '节假日' : ''"
                @change="e => handleChange(e.target.value, record.key, 'name')"
              />
              <template v-else>
                {{ text }}
                <a-tag color="red" v-show="record.holiday === 1">
                  节假日
                </a-tag>
              </template>
            </template>
            <template
              v-for="(col, i) in [
                'price',
                'hotel_cost',
                'ticket_cost',
                'sea_cost'
              ]"
              :slot="col"
              slot-scope="text, record"
            >
              <a-input-number
                v-if="record.editable"
                :key="col"
                class="page-form-input"
                size="small"
                :min="0"
                :step="0.01"
                :value="text"
                :placeholder="columns[i + 1].title"
                :disabled="formData.days === 0 && col === 'hotel_cost'"
                @change="value => handleChange(value, record.key, col)"
              />
              <template v-else>{{ text }}</template>
            </template>
            <template slot="ship_cost" slot-scope="text, record">
              <a-input-number
                v-if="record.editable"
                key="ship_cost"
                class="page-form-input"
                size="small"
                :min="0"
                :step="0.01"
                :value="text"
                :disabled="formData.set_out === 1"
                placeholder="船票费用"
                @change="value => handleChange(value, record.key, 'ship_cost')"
              />
              <template v-else>{{ text }}</template>
            </template>
            <template slot="profit" slot-scope="text, record">
              <a-input-number
                v-if="record.editable"
                key="profit"
                class="page-form-input"
                size="small"
                :min="0"
                :step="0.01"
                :value="text"
                :disabled="formData.type !== 3"
                placeholder="分销利润"
                @change="value => handleChange(value, record.key, 'profit')"
              />
              <template v-else>{{ text }}</template>
            </template>
            <template slot="action" slot-scope="text, record">
              <template v-if="record.editable">
                <span v-if="record.isNew">
                  <a @click="save(record)">添加</a>
                  <a-divider type="vertical" />
                  <a-popconfirm
                    title="是否要删除此分类？"
                    @confirm="remove(record.key)"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                </span>
                <span v-else>
                  <a @click="save(record)">保存</a>
                  <a-divider type="vertical" />
                  <a @click="cancel(record.key)">取消</a>
                </span>
              </template>
              <span v-else>
                <a @click="toggle(record.key)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                  title="是否要删除此行？"
                  @confirm="remove(record.key)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item class="page-button-item">
          <a-button type="dashed" icon="plus" @click="newPrice(false)">
            <template v-if="formData.holiday === 1">
              新增工作日价格分类
            </template>
            <template v-else>
              新增价格分类
            </template>
          </a-button>
          <a-button
            v-show="formData.holiday === 1"
            type="dashed"
            icon="plus"
            @click="newPrice(true)"
            style="margin-left: 20px;"
            >新增节假日价格分类
          </a-button>
          <a-button
            class="page-button-submit"
            type="primary"
            size="large"
            html-type="submit"
            :disabled="!isEnable"
            :loading="!isEnable"
          >
            确认提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-card>
</template>

<script>
export default {
  data() {
    const columns = [
      {
        title: "价格分类",
        dataIndex: "name",
        width: "20%",
        scopedSlots: { customRender: "name" }
      },
      {
        title: "分类售价",
        dataIndex: "price",
        scopedSlots: { customRender: "price" }
      },
      {
        title: "酒店费用",
        dataIndex: "hotel_cost",
        scopedSlots: { customRender: "hotel_cost" }
      },
      {
        title: "景区门票",
        dataIndex: "ticket_cost",
        scopedSlots: { customRender: "ticket_cost" }
      },
      {
        title: "海上项目",
        dataIndex: "sea_cost",
        scopedSlots: { customRender: "sea_cost" }
      },
      {
        title: "进出岛交通",
        dataIndex: "ship_cost",
        scopedSlots: { customRender: "ship_cost" }
      },
      {
        title: "分销利润",
        dataIndex: "profit",
        scopedSlots: { customRender: "profit" }
      },
      {
        title: "操作",
        key: "action",
        width: "12%",
        scopedSlots: { customRender: "action" }
      }
    ]

    return {
      isEnable: true,
      loading: true,
      columns: columns,
      priceList: [],
      formData: {
        id: undefined,
        name: undefined,
        holiday: 0,
        type: 1,
        set_out: 1,
        date: [],
        min_people: undefined,
        days: undefined,
        change_time: undefined,
        car_cost: undefined,
        content: undefined,
        remark: undefined
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请输入有效的产品名称",
            trigger: "blur"
          },
          {
            max: 30,
            message: "请输入有效的产品名称",
            trigger: "blur"
          }
        ],
        holiday: [
          {
            required: true,
            message: "请选择价格模式",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "请选择产品类型",
            trigger: "blur"
          }
        ],
        set_out: [
          {
            required: true,
            message: "请选择出发地点",
            trigger: "blur"
          }
        ],
        date: [
          {
            required: true,
            message: "请设置产品有效时间",
            trigger: "change"
          }
        ],
        min_people: [
          {
            required: true,
            message: "请输入最少成团人数",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "请输入整数",
            trigger: "blur"
          }
        ],
        days: [
          {
            required: true,
            message: "请输入住宿天数",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "请输入整数",
            trigger: "blur"
          }
        ],
        change_time: [
          {
            required: true,
            message: "请输入最晚退改天数",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "请输入整数",
            trigger: "blur"
          }
        ],
        car_cost: [
          {
            required: true,
            message: "请输入交通费用基数",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "请输入整数",
            trigger: "blur"
          }
        ],
        content: [
          {
            required: true,
            message: "请输入产品详情",
            trigger: "blur"
          },
          {
            min: 10,
            message: "请输入最少10字符",
            trigger: "blur"
          },
          {
            max: 300,
            message: "最多输入300字符",
            trigger: "blur"
          }
        ],
        remark: [
          {
            max: 300,
            message: "最多输入300字符",
            trigger: "blur"
          }
        ]
      }
    }
  },
  computed: {
    isEdit() {
      return this.$route.path === "/product/edit"
    }
  },
  mounted() {
    if (this.$route.path === "/product/edit") {
      if (this.$route.query.id) {
        this.$request({
          url: "/product",
          method: "get",
          params: {
            id: this.$route.query.id
          }
        }).then(res => {
          //产品
          this.formData.id = res.data.product_info.id
          this.formData.name = res.data.product_info.name
          this.formData.holiday = res.data.product_info.holiday
          this.formData.type = res.data.product_info.type
          this.formData.set_out = res.data.product_info.set_out
          this.formData.min_people = res.data.product_info.min_people
          this.formData.days = res.data.product_info.days
          this.formData.change_time = res.data.product_info.change_time
          this.formData.car_cost = res.data.product_info.car_cost
          this.formData.content = res.data.product_info.content
          this.formData.remark = res.data.product_info.remark
          this.formData.date.push(res.data.product_info.start_date)
          this.formData.date.push(res.data.product_info.end_date)
          //价格
          let priceList = res.data.product_price
          for (let i = 0; i < priceList.length; i++) {
            priceList[i].key = i
            priceList[i].editable = false
            priceList[i].isNew = false
          }
          this.priceList = priceList
          this.loading = false
        })
      } else {
        this.$router.replace("/product/list")
      }
    } else {
      this.loading = false
    }
  },
  methods: {
    //价格模式状态改变
    holidayChange(value) {
      if (value === 0) {
        const newData = this.priceList.filter(item => item.holiday === 0)
        this.priceList = newData
      }
    },
    //产品类型改变
    typeChange(value) {
      if (value !== 3) {
        let priceList = [...this.priceList]
        for (let i = 0; i < priceList.length; i++) {
          priceList[i].profit = 0
        }
        this.priceList = priceList
      }
    },
    //出发地点改变
    setOutChange(value) {
      if (value === 1) {
        let priceList = [...this.priceList]
        for (let i = 0; i < priceList.length; i++) {
          priceList[i].ship_cost = 0
        }
        this.priceList = priceList
      }
    },
    daysChange(value) {
      if (value === 0) {
        let priceList = [...this.priceList]
        for (let i = 0; i < priceList.length; i++) {
          priceList[i].hotel_cost = 0
        }
        this.priceList = priceList
      }
    },
    //处理数据修改
    handleChange(value, key, column) {
      const newData = [...this.priceList]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.priceList = newData
      }
    },
    //新增价格分类
    newPrice(h = false) {
      const length = this.priceList.length
      this.priceList.push({
        key:
          length === 0
            ? "0"
            : (parseInt(this.priceList[length - 1].key) + 1).toString(),
        editable: true,
        isNew: true,
        holiday: h ? 1 : 0,
        name: "",
        price: "",
        hotel_cost: this.formData.days === 0 ? 0 : "",
        ticket_cost: "",
        sea_cost: "",
        ship_cost: this.formData.set_out === 1 ? 0 : "",
        profit: this.formData.type == 3 ? "" : 0
      })
    },
    //保存数据
    save(record) {
      if (
        record.name === "" ||
        record.price === "" ||
        record.hotel_cost === "" ||
        record.ticket_cost === "" ||
        record.sea_cost === "" ||
        record.ship_cost === "" ||
        record.profit === "" ||
        record.price === null ||
        record.hotel_cost === null ||
        record.ticket_cost === null ||
        record.sea_cost === null ||
        record.ship_cost === null ||
        record.profit === null
      ) {
        this.$message.error("请填写完整的分类信息")
        return
      }
      const target = this.priceList.find(item => item.key === record.key)
      target.editable = false
      target.isNew = false
    },
    //删除数据
    remove(key) {
      const newData = this.priceList.filter(item => item.key !== key)
      this.priceList = newData
    },
    //取消编辑
    cancel(key) {
      const target = this.priceList.find(item => item.key === key)
      Object.keys(target).forEach(key => {
        target[key] = target._originalData[key]
      })
      target._originalData = undefined
    },
    //编辑
    toggle(key) {
      const target = this.priceList.find(item => item.key === key)
      target._originalData = { ...target }
      target.editable = !target.editable
    },
    //提交数据
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          if (!this.priceList.length > 0) {
            this.$message.error("请填写价格分类信息")
            return
          }
          let weekday = false
          let holiday = false
          for (let i = 0; i < this.priceList.length; i++) {
            if (this.priceList[i].editable === true) {
              this.$message.error("您有未保存的价格分类")
              return
            }
            if (this.priceList[i].holiday === 0) {
              weekday = true
            } else {
              holiday = true
            }
          }
          if (this.formData.holiday === 1) {
            if (!weekday || !holiday) {
              this.$message.error("需同时填写工作日和节假日价格分类")
              return
            }
          }
          this.isEnable = false
          let method = this.formData.id ? "put" : "post"
          this.$request({
            url: "/product",
            method: method,
            data: {
              id: this.formData.id,
              name: this.formData.name,
              holiday: this.formData.holiday,
              type: this.formData.type,
              set_out: this.formData.set_out,
              start_date: this.formData.date[0],
              end_date: this.formData.date[1],
              min_people: this.formData.min_people,
              days: this.formData.days,
              change_time: this.formData.change_time,
              car_cost: this.formData.car_cost,
              content: this.formData.content,
              remark: this.formData.remark,
              price_list: this.priceList
            }
          })
            .then(res => {
              this.$message.success(res.message)
              setTimeout(() => {
                this.$router.replace("/product/list")
              }, 2000)
            })
            .catch(() => {
              this.isEnable = true
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
}

.page {
  padding: 20px;
}

.page-table {
  margin-top: 20px;
}

.page-input-number {
  width: 100%;
}

.page-form-input {
  margin: -5px 0;
}

.page-button-item {
  width: 100%;
  margin-top: 30px;
}

.page-button-item button {
  width: 20%;
}

.page-button-submit {
  float: right;
}
</style>
