<template>
  <a-card :title="$route.meta.title" :bordered="false" hoverable>
    <router-link slot="extra" :to="{ path: 'create' }">新增产品</router-link>
    <a-table
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data-source="data.data"
      :pagination="pagination"
      @change="pageChange"
    >
      <span slot="state" slot-scope="scope">
        <a-badge :color="scope | stateCode" :text="scope | stateText" />
      </span>
      <span slot="name" slot-scope="scope, record">
        <a-tag v-if="record.type === 1" color="green">自营</a-tag>
        <a-tag v-if="record.type === 2" color="blue">OTA</a-tag>
        <a-tag v-if="record.type === 3" color="orange">分销</a-tag>
        {{ record.name }}
        <a-tag v-if="record.set_out === 1" color="green">仅长岛出发</a-tag>
        <a-tag v-if="record.set_out === 2" color="pink">支持岛外出发</a-tag>
        <a-tag v-if="record.holiday === 1" color="red">区分节假日</a-tag>
        <a-tag v-if="record.days === 0" color="red">一日游</a-tag>
      </span>
      <span slot="min_people" slot-scope="scope">
        <a-tag color="purple">{{ scope }} 人</a-tag>
      </span>
      <span slot="date" slot-scope="scope, record">
        <span :class="{ red: isExpire(record.start_date, record.end_date) }"
          >{{ record.start_date }} ～ {{ record.end_date }}</span
        >
      </span>
      <span slot="action" slot-scope="scope">
        <router-link :to="{ path: 'edit', query: { id: scope.id } }"
          >编辑</router-link
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="bottomRight"
          :title="getStateTitle(scope.name, scope.state)"
          @confirm="changeState(scope.id, scope.state)"
        >
          <a v-if="scope.state == NORMAL">暂停</a>
          <a v-else-if="scope.state == PAUSE">启用</a>
          <a v-else disabled="true">暂停</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="bottomRight"
          :title="getDeleteTitle(scope.name)"
          @confirm="remove(scope.id)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { PAGE_SIZE } from "@/config"
import { STATE_MAP, NORMAL, PAUSE } from "@/utils/state-utils"

export default {
  data() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: "80px"
      },
      {
        title: "状态",
        dataIndex: "state",
        scopedSlots: { customRender: "state" }
      },
      {
        title: "产品名称",
        dataIndex: "name",
        scopedSlots: { customRender: "name" }
      },
      {
        title: "最低人数",
        dataIndex: "min_people",
        scopedSlots: { customRender: "min_people" }
      },
      {
        title: "有效时间",
        key: "date",
        scopedSlots: { customRender: "date" }
      },
      {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" }
      }
    ]
    return {
      NORMAL: NORMAL,
      PAUSE: PAUSE,
      columns: columns,
      loading: false,
      data: [],
      pagination: {}
    }
  },
  activated() {
    this.getData({
      page: this.pagination.current,
      size: this.pagination.pageSize
    })
  },
  filters: {
    stateCode(code) {
      return STATE_MAP[code].state
    },
    stateText(code) {
      return STATE_MAP[code].text
    }
  },
  methods: {
    isExpire(start_date, end_date) {
      let now = this.$moment(this.$moment().format("YYYY-MM-DD")).unix()
      let start = this.$moment(start_date).unix()
      let end = this.$moment(end_date).unix()
      return now < start || now > end
    },
    getDeleteTitle(name) {
      return "您确定要删除" + name + "吗？"
    },
    remove(id) {
      this.$request({
        url: "/product",
        method: "delete",
        data: {
          id: id
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data.splice(index, 1)
        this.$message.success(res.message)
      })
    },
    getStateTitle(name, state) {
      if (state == NORMAL) {
        return "您确定要暂停" + name + "吗？"
      } else {
        return "您确定要启用" + name + "吗？"
      }
    },
    changeState(id, state) {
      let newState
      if (state == NORMAL) {
        newState = PAUSE
      } else {
        newState = NORMAL
      }
      this.$request({
        url: "/product",
        method: "put",
        data: {
          id: id,
          state: newState
        }
      }).then(res => {
        const index = this.data.data.findIndex(item => id === item.id)
        this.data.data[index].state = newState
        this.$message.success(res.message)
      })
    },
    pageChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.pagination = pager
      this.getData({ page: pagination.current, size: pagination.pageSize })
    },
    getData({ page = 1, size = PAGE_SIZE } = {}) {
      this.loading = true
      this.$request({
        url: "/getProductList",
        method: "get",
        params: {
          page: page,
          size: size
        }
      }).then(res => {
        this.data = res.data
        const pagination = { ...this.pagination }
        pagination.total = res.data.total
        pagination.showSizeChanger = true
        this.pagination = pagination
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.ant-card {
  min-height: 100%;
}

.red {
  color: red;
}
</style>
