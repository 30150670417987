export const DELETE = 0
export const CREATE = 1
export const PLANNING = 2
export const READY = 3
export const COMPLETE = 4
export const END = 5

export const ORDER_STATE_MAP = {
  [DELETE]: {
    state: "pink",
    text: "已取消"
  },
  [CREATE]: {
    state: "red",
    text: "未提交"
  },
  [PLANNING]: {
    state: "orange",
    text: "待分配"
  },
  [READY]: {
    state: "blue",
    text: "待服务"
  },
  [COMPLETE]: {
    state: "cyan",
    text: "已完成"
  },
  [END]: {
    state: "green",
    text: "已结算"
  }
}
