<template>
  <a-spin :spinning="spinning">
    <a-form-model
      ref="iform"
      class="page-form"
      :model="formData"
      :rules="rules"
      @submit="handleSubmit"
      @submit.native.prevent
    >
      <a-form-model-item
        label="旅行社下单短信通知"
        labelAlign="left"
        :labelCol="{ span: 12 }"
        :wrapperCol="{ span: 12 }"
      >
        <a-switch v-model="formData.order_notice" />
      </a-form-model-item>
      <a-form-model-item
        label="订单分配短信通知"
        labelAlign="left"
        :labelCol="{ span: 12 }"
        :wrapperCol="{ span: 12 }"
      >
        <a-switch v-model="formData.arrange_notice" />
      </a-form-model-item>
      <a-form-model-item
        label="节假日交通费用差价"
        labelAlign="left"
        prop="holiday_car_cost"
      >
        <a-input-number
          class="page-input-number"
          v-model="formData.holiday_car_cost"
          :min="0"
          placeholder="交通费用差价"
        />
      </a-form-model-item>
      <a-form-model-item class="page-submit">
        <a-button
          type="primary"
          size="large"
          html-type="submit"
          :loading="loading"
        >
          确认提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>

<script>
export default {
  data() {
    return {
      spinning: true,
      loading: false,
      formData: {
        order_notice: undefined,
        arrange_notice: undefined,
        holiday_car_cost: undefined
      },
      rules: {
        holiday_car_cost: [
          {
            required: true,
            message: "请输入交通费用差价",
            trigger: "blur"
          }
        ]
      }
    }
  },
  mounted() {
    this.$request({
      url: "/setting",
      method: "get"
    }).then(res => {
      this.formData = res.data
      this.spinning = false
    })
  },
  methods: {
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.loading = true
          this.$request({
            url: "/setting",
            method: "put",
            data: this.formData
          })
            .then(res => {
              this.loading = false
              this.$message.success(res.message)
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 6px;
}

.page-form {
  min-width: 300px;
  width: 400px;
}

.page-submit {
  margin-top: 15px;
}

.page-input-number {
  width: 60%;
}
</style>
